<template>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>SK(주) C&C 대량 단체문자 발송 - Message To-Go | SMS, LMS, 알림톡 API 기업 문자 서비스</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
    <!--    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>-->
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
    <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
    <link rel="stylesheet" href="../assets/css/theme.css">
    <link rel="stylesheet" href="../assets/css/loopple/loopple.css">
  </head>

  <body class="g-sidenav-show">
  <div class="container position-sticky z-index-sticky top-0">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
          <div class="container-fluid">
                        <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                        <a class="navbar-brand ms-sm-3" href="/home#top">                          
                          <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                          <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                        </a>
            <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon mt-2">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </span>
            </button>
            <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
              <ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
                <li class="nav-item">
                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
                    <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
                    <span class="font-weight-bolder" style="font-size: larger;">기능</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
                    <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
                    <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
                  </router-link>
                </li>
                <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
                  <router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`" style="font-size: medium;">
                    회원가입/로그인
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <section class="py-3" style="font-size: 0.8rem; margin-left: 30px;">
    <br><br>
    <h1 style="margin-right:0cm;margin-left:0cm;font-size:32px;font-family:굴림;font-weight:bold;margin-top:60.0pt;margin-bottom:37.5pt;background:white;"><span style='font-size:30px;font-family:"맑은 고딕";color:#111111;font-weight:normal;'>Message To-Go&nbsp;</span><span style='font-size:30px;font-family:"맑은 고딕";color:#111111;font-weight:normal;'>서비스 이용약관</span></h1>
<ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제1</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">목적</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제2</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">용어의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">정의</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제3</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">약관의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">게시와</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">개정</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제4</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용계약</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">체결</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제5</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">개인정보</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">수집</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제6</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">개인정보보호</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">의무</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제7</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용자정보의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">변경</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제8</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용자에</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">대한</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">통지</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제9</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">본</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">약관</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">외</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">준칙</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제10</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">회사의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">의무</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제11</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용자의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">의무</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제12</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용자의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">책임</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제13</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">불만형태별</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">처리절차</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">처리기간</span><span style="font-size:14px;color:#111111;">&nbsp;</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="font-size:14px;">제14조 서비스 제공 불가 시 처리</span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제15</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">서비스의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">제공</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">제한</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제16</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">정보의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">제공</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제17</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">문자</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">발송량</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">제한</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제18</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">권리의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">귀속</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제19</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">계약사항의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">변경</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제20</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용자의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">계약의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">해제</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">해지</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제21</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">회사의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">계약의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">해제</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">해지</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제22</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용자가</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">제3</span><span style="font-size:14px;color:#111111;">자에게</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">서비스</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">임대</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">시</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">준수사항</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제23</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용요금의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">종류</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">계산방법</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제24</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용요금의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">청구</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">납입</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제25</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이용요금의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">환불</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">이의제기</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제26</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">손해배상의</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">범위</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">청구</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제27</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">면책</span></span></li>
    <li style='margin-top:7.5pt;margin-right:0cm;margin-bottom:3.75pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";color:#111111;line-height:normal;background:white;'><span style="color:windowtext;"><span style="font-size:14px;color:#111111;">제28</span><span style="font-size:14px;color:#111111;">조</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">준거법</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">및</span><span style="font-size:14px;color:#111111;">&nbsp;</span><span style="font-size:14px;color:#111111;">재판관할</span></span></li>
</ul>
<p style='margin-right:0cm;margin-left:36.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:7.5pt;margin-bottom:3.75pt;text-align:left;line-height:normal;background:white;'><span style="font-size:14px;color:#111111;">&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제1장 총 칙</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제1조&nbsp;(목적)</span></h3>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>본 약관은 SK㈜&nbsp;(이하 &ldquo;회사&rdquo;)가 제공하는&nbsp;Message To-Go&nbsp;서비스(이하 &ldquo;서비스&rdquo;)의 이용에 대하여 &ldquo;회사&rdquo;와 이용자(본 약관에 동의하고 회사가 제시한 양식과 절차에 따라 이용신청 및 회원등록을 완료한 서비스 이용자를 말합니다.&nbsp;이하 &ldquo;이용자&rdquo;)의 권리ㆍ의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제2조&nbsp;(용어의 정의)</span></h3>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span></p>
<ol style="list-style-type: decimal;margin-left:41px;">
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;서비스&rdquo;라 함은 이용자가 전송하고자 하는 내용ㆍ정보 등을 문자메시지(SMS),&nbsp;멀티미디어메시지(MMS)&nbsp;등의 형태로 변환하여 전송하거나 카카오톡을 통한 알림톡 또는 친구톡의 형태로 변환하여 전송하고 알림톡 또는 친구톡 실패 시 대체발송 옵션 설정에 따라 문자메시지,&nbsp;멀티미디어메시지 등의 형태로 변환하여 전송하는 서비스를 의미합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;카카오톡&rdquo;이란 주식회사 카카오(이하 &ldquo;카카오&rdquo;)가 운영하는 모바일 메신저 기반의 실시간 커뮤니케이션 서비스 또는 해당 서비스를 제공하는 어플리케이션을 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;알림톡&rdquo;이란 한글,&nbsp;영문 등의 구별 없이 띄어쓰기 포함&nbsp;1,000자이하의 텍스트 메시지로서 주문,&nbsp;예약,&nbsp;결제,&nbsp;배송 정보 등 정보통신망 이용 촉진 및 정보보호 등에 관한 법률(이하 &ldquo;정보통신망법&rdquo;)&nbsp;및 한국인터넷진흥원 지침 상 광고성 정보의 예외로 분류되는 정보 중 일부를 카카오톡 채널 추가 여부와 상관 없이 카카오톡 이용자에게 발송 가능한 메시지를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;친구톡&rdquo;이란 채널 추가기능을 통해 발신자의 카카오톡 채널의 메시지를 받아볼 것을 동의한 카카오톡 이용자에게만 발송 가능한 메시지를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;카카오톡 채널&rdquo;이란 카카오톡의 공식 계정 서비스 중 하나로서 기업 및 단체가 알림톡 또는 친구톡(이하 통칭하여 &ldquo;카카오톡 비즈메시지&rdquo;)을 발송하거나 카카오톡 이용자와 커뮤니케이션할 수 있는 서비스를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;발신프로필&rdquo;이란 &ldquo;카카오톡 비즈메시지&rdquo;를 발송하고 &ldquo;수신자&rdquo;가 카카오톡 비즈메시지&rdquo;의 발신처를 확인하기 위하여 사용하는 &ldquo;발신자&rdquo; 식별정보를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;브랜드&rdquo;란 이용자가 &ldquo;알림톡&rdquo; 또는 &ldquo;친구톡&rdquo;을 발송하기 위하여 통신사에 등록하여야 하는 &ldquo;발신자&rdquo; 식별정보로서 자기 또는 자기가 보유한 브랜드와 동일한 명의의 정보를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;템플릿&rdquo;이란 이용자가 활용할 수 있도록 통신사에서 기본으로 제공하는 메시지 형식을 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;발신자&rdquo;란 다음 각목 중 하나에 해당에는 자로서 본 약관 상 이용자 또는 이용자의 고객(이용자로부터 서비스를 임대&middot;재판매 받은 제3자)을 말합니다</span></li>
</ol>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">가.&nbsp;서비스를 활용하여&nbsp;SMS, MMS등을 발송하는 자</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">나.&nbsp;자기 또는 자기가 보유한 브랜드와 동일한 명의의 &ldquo;발신프로필&rdquo;로 서비스를 활용하여 카카오톡 비즈메시지를 발송하는 자</span></p>
<ol style="list-style-type: undefined;margin-left:41px;">
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;수신자&rdquo;란 발신자로부터 메시지를 수신하는 자를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;이용계약&rdquo;이라 함은 회사가 제공하는 서비스 이용과 관련하여 회사와 이용자 간에 체결하는 계약을 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;ID&rdquo;(아이디)라 함은 이용자의 식별과 서비스 이용을 위하여 이용자가 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;비밀번호&rdquo;라 함은 이용자가 부여 받은&nbsp;ID와 일치하는 이용자임을 확인하고 비밀보호를 위해 이용자 자신이 정한 문자 또는 숫자의 조합을 의미합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;스팸&rdquo;이라 함은 정보통신망법 등을 위반하여 수신자가 원하지 않는데도 불구하고 일방적으로 전송 또는 게시되는 영리목적의 광고성 정보 또는 메시지를 의미합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;스미싱&rdquo;이란 메시지 내용 중 인터넷 주소를 클릭하면 악성코드가 설치되어 수신자가 모르는 사이에 금전적 피해 또는 개인&middot;금융정보 탈취 피해를 야기하는 메시지를 말합니다.</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>&ldquo;블랙리스트(불법 전화번호 목록)&rdquo;라 함은 발신번호 거짓표시,&nbsp;보이스피싱,&nbsp;불법스팸 등에 이용되어 전기통신역무 제공이 중지된 전화번호가 메시지를 발송하는 부가통신역무(전기통신사업법 제2조제14호나목에 해당)에 재사용되는 것을 방지하기 위해 한국인터넷진흥원이 관리하는 전화번호 목록을 말합니다.</span></li>
</ol>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;background:white;'><span style="color:#111111;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>② 본 조 제1항에 규정되지 않은 용어의 정의는 &ldquo;정보통신망법&rdquo; 등 관련 법령 및 일반 상관례에 따릅니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제3조&nbsp;(약관의 게시와 개정)</span></h3>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";'>
    <ul style="margin-bottom:0cm;list-style-type: undefined;margin-left:59px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";'><span style='font-family:"맑은 고딕";font-size:10.0pt;color:#111111;'>&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스 초기 화면(https://message.to-go.io/)에 게시합니다.</span></li>
    </ul>
</div>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 &ldquo;약관의 규제에 관한 법률&rdquo;,&rdquo;정보통신망법&rdquo;,&nbsp;&ldquo;전기통신사업법&rdquo;,&nbsp;&rdquo;전자상거래 등에서의 소비자보호에 관한 법률&rdquo; 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사가 약관을 개정할 경우에는 시행일자 및 개정사유를 명시하여 기존 약관과 함께 그 시행일자&nbsp;7일 전부터 시행일자 전일까지 공지합니다.&nbsp;다만,&nbsp;이용자에게 불리한 약관의 개정의 경우에는 시행일로부터&nbsp;30일 전 서비스 홈페이지에 공지하고 이용자가 등록한 이메일로 전송하는 방법으로 이용자에게 통지합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사가 전항에 따라 이용자에게 개정약관을 공지 또는 통지하면서 이용자에게 약관시행일까지 의사표시를 하지 않으면 승인한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 이용자가 개정약관에 동의한 것으로 봅니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 이용자는 개정된 약관에 대해 동의하지 않을 권리가 있습니다.&nbsp;이용자가 개정약관의 적용에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며,&nbsp;이 경우 이용자는 이용계약을 해지할 수 있습니다.&nbsp;단,&nbsp;기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 이용자는 약관의 변경에 대하여 주의의무를 다하여야 하며 변경된 약관으로 인한 이용자의 피해는 회사가 책임지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 이 약관의 적용기간은 이용계약 체결일로부터 해지일까지로 규정합니다.&nbsp;단,&nbsp;회사와 이용자 사이에 채권 또는 채무관계가 있을 경우에는 채권,&nbsp;채무의 완료일까지로 규정합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제2장 이용계약 체결 및 관리</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제4조&nbsp;(이용계약 체결)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 이용계약은 회사가 제공하는 서비스를 이용하고자 하는 자가 본 약관을 읽고 동의를 한 다음 회사가 제시하는 양식과 절차에 따라 이용신청을 한 내용에 대해 회사가 승낙함으로써 회사와 서비스 이용자 간 이용계약이 체결됩니다.&nbsp;단,&nbsp;이용신청에 별도의 자료제출이 요청되는 경우에는 예외로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 다음 각호에 해당하는 신청에 대해서는 승낙을 하지 않거나 사후에 이용계약을 해제 또는 해지 할 수 있습니다.</span></p>
<ol style="list-style-type: circle;">
    <li><span style='font-family:"맑은 고딕";color:#111111;'>1.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>타인의 명의로 이용신청을 하는 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>2.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>허위의 정보를 기재하거나 허위서류를 첨부하여 이용신청을 하는 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>3.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>회사가 제시하는 이용신청 절차를 완료하지 않거나 본인확인이 불가능한 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>4.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>이용신청을 하고 회사가 별도로 규정하는 기간 내에 회사가 별도로 요구하는 자료제출을 하지 않는 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>5.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>회사의 서비스 제공을 저해하거나 타 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>6.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>제3자의 신용카드,&nbsp;유/무선 전화,&nbsp;은행 계좌 등을 무단으로 이용 또는 도용하여 서비스 이용요금을 결제하는 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>7.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>제20조에 의하여 회사로부터 계약해지를 당한 이후&nbsp;1년이 경과하지 않은 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>8.&nbsp;</span><span style='font-family:"맑은 고딕";color:#111111;'>기타 관련법령 등을 기준으로 하여 명백하게 현행 법령,&nbsp;사회질서 또는 미풍양속에 반하는 위법행위를 할 목적으로 이용신청을 하는 경우</span></li>
</ol>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사는 본 조 제2항 각 호에 따라 이용신청이 이루어지지 않는지 관리&middot;감독할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사는 다음 각호에 해당하는 신청에 대해서는 승낙을 지연할 수 있습니다.</span></p>
<ol style="list-style-type: circle;">
    <li><span style='font-family:"맑은 고딕";color:#111111;'>회사의 설비에 여유가 없거나 기술적 장애가 있는 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우</span></li>
    <li><span style='font-family:"맑은 고딕";color:#111111;'>기타 회사가 재정적,&nbsp;기술적으로 필요하다고 인정하는 경우</span></li>
</ol>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제5조&nbsp;(개인정보 수집)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 개인정보의 수집 시 관련법규에 따라 별도의 가입신청서 및 개인정보처리방침에 그 수집범위 및 목적을 사전 고지합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사는 이용자가 서비스 화면에서 자신의 개인정보에 대한 수집,&nbsp;이용 또는 제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취해야 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제6조&nbsp;(개인정보보호 의무)</span></h3>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>회사는 개인정보보호법,&nbsp;정보통신망법 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다.&nbsp;개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다.&nbsp;다만,&nbsp;회사의 공식사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제7조&nbsp;(이용자정보의 변경)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 이용자는 개인정보설정 화면을 통하여 본인의 정보를 열람 및 수정할 수 있습니다.&nbsp;다만,&nbsp;법인 이용자는 홈페이지 기재된 회사의 전화 및 이메일로 법인정보의 수정을 요청하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 이용자는 서비스 계약에 필요한 정보 등을 회사에 허위로 제공해선 안 되며,&nbsp;정보 변경 시 지체 없이 제1항의 방법을 통하여 변경 사항을 수정해야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 제2항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제8조&nbsp;(이용자에 대한 통지)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사가 이용자에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 이용자가 제공한 이메일, (휴대)전화번호 등으로 통지할 수 있습니다.&nbsp;단,&nbsp;이용자가 이메일, (휴대)전화번호 정보를 허위로 제출하거나 변경된 정보를 회사에 알리지 않은 경우 회사는 이용자가 제출한 정보로 발송한 때에 이용자에게 도달한 것으로 봅니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 전체 또는 불특정 다수 이용자에 대한 통지의 경우&nbsp;7일 이상 서비스에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 이용자는 회사에 실제로 연락이 가능한 이메일, (휴대)전화번호,&nbsp;주소 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며 회사의 통지를 확인하여야 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제9조&nbsp;(본 약관 외 준칙)</span></h3>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 정부가 제정한 전기통신기본법,&nbsp;전기통신사업법,&nbsp;정보통신망법,&nbsp;전자상거래 등에서의 소비자보호에 관한 법률,&nbsp;약관의 규제에 관한 법률,&nbsp;전자거래기본법,&nbsp;전자서명법,&nbsp;방문판매 등에 관한 법률,&nbsp;소비자기본법 및 기타 관계법령 또는 상관례에 따릅니다.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제3장 계약당사자의 권리 및 의무</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제10조&nbsp;(회사의 의무)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며,&nbsp;계속적이고 안정적으로 서비스를 제공하기 위하여 노력합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 이용자의 개인정보보호를 위하여 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사는 서비스 제공을 위한 시스템에 장애가 발생하거나 또는 그 시스템이 고장 발생 시에는 이를 최대한 신속히 수리 또는 복구합니다.&nbsp;다만,&nbsp;천재지변,&nbsp;비상사태 또는 그밖에 부득이한 경우에는 그 서비스를 일시 중단하거나 중지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사는 서비스 제공과 관련하여 취득한 이용자의 정보를 본인의 사전 승낙 없이 타인에게 누설 또는 배포할 수 없으며 상업적 목적으로도 사용할 수 없습니다.&nbsp;다만,&nbsp;관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우 또는 회사가 정한 기간 동안 이용요금을 체납하여 이용자의 동의를 얻어 신용정보사업자 또는 신용정보집중기관에 제공하는 경우에는 그러하지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 회사는 이용자의 서비스 제공목적에 맞는 서비스 이용여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 회사는 광고성 정보의 전송 또는 수신으로 서비스의 제공에 장애가 일어나거나 일어날 우려가 있는 경우 및 이용자가 광고성 정보의 수신을 원하지 않는 경우 해당 서비스 제공을 거부하는 조치를 취할 수 있으며,&nbsp;조치 사실을 서비스를 제공받는 이용자 등 이해관계인에게 알려야 합니다.&nbsp;다만 미리 알리는 것이 곤란한 경우에는 거부조치 후 지체 없이 알려야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 회사는 이용계약을 통하여 회사가 이용자에게 제공하는 서비스가 정보통신망법 제50조 또는 제50조의&nbsp;8을 위반하여 영리목적의 광고성 정보 전송에 이용되고 있는 경우 해당 서비스 제공을 거부하는 등의 조치를 할 수 있으며,&nbsp;조치 사실을 서비스를 제공받는 이용자 등 이해관계인에게 알려야 합니다.&nbsp;다만 미리 알리는 것이 곤란한 경우에는 거부조치 후 지체 없이 알려야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑧ 회사는 스팸메시지 또는 스미싱(이하 통칭하여 &ldquo;불법스팸&rdquo;)으로부터 이용자를 보호하기 위하여 스팸차단(필터링)&nbsp;부가 서비스를 통해 해당 문자메시지가 수신되지 않도록 차단할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑨ 회사는 불법스팸전송자 적발,&nbsp;불법스팸 차단 및 감축 등을 통해 이용자의 불법스팸 수신 피해를 최소화하기 위해 자동으로 스팸을 차단해주는 스팸차단(필터링)&nbsp;기능을 통해 차단된 문자의 내용,&nbsp;불법스팸 발송 사업자 정보 등을 한국인터넷진흥원에 제공할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑩ 회사는 &ldquo;불법스팸&rdquo;을 전송한 사실을 확인한 경우,&nbsp;한국인터넷진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑪ 회사는 정보통신망법 제64조 제2항 및 제65조 제3항에 따라 방송통신위원회 및 한국인터넷진흥원에게 불법스팸 전송자의 성명</span><span style='font-family:"MS Mincho";color:#111111;'>・</span><span style="color:#111111;">주소</span><span style='font-family:"MS Mincho";color:#111111;'>・</span><span style="color:#111111;">주민등록번호</span><span style='font-family:"MS Mincho";color:#111111;'>・</span><span style="color:#111111;">이용기간</span><span style='font-family:"MS Mincho";color:#111111;'>・</span><span style="color:#111111;">연락처</span><span style="color:#111111;">&nbsp;등에 대한 자료의 제출 요청이 있는 경우에 해당 자료를 제공할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑫ 회사는 한국인터넷진흥원으로부터 불법스팸 신고가 접수된 경우 서비스 제공 목적에 맞는 서비스 이용 여부를 확인하기 위하여 다량의 문자 전송 여부 및 서비스 변경 이력을 확인할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑬ 회사는 이용자가 제11조를 이행하지 않는 경우 서비스 이용을 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑭ 회사는 불법스팸 수신 거부처리 등 불법스팸 관련 민원을 자체적으로 처리하기 위한 고충처리 창구를 운영합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑮ 회사는 공공의 질서 또는 미풍양속을 해하는 내용에 대한 경찰서,&nbsp;전파관리소,&nbsp;방송통신위원회 등 수사기관의 수사 협조를 위해 이용고객으로부터 전송된 메시지에 대한 전송로그를&nbsp;1년간 보관합니다.&nbsp;이 기간이 경과한 메시지 로그에 대해서는 자동 삭제되며,&nbsp;삭제된 로그에 대해서는 어떠한 경우에도 제공이 불가합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style='font-family:"Cambria Math","serif";color:#111111;'>⑯</span><span style="color:#111111;">&nbsp;</span><span style="color:#111111;">회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정되는 경우에는 이를 즉시 처리합니다.&nbsp;이용자가 이용신청 시 기입한&nbsp;(휴대)전화번호나 이메일로 처리과정 및 결과를 통보할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style='font-family:"Cambria Math","serif";color:#111111;'>⑰</span><span style="color:#111111;">&nbsp;</span><span style="color:#111111;">회사는 전기통신사업법 제84조의2&nbsp;및 과학기술정보통신부 고시 &ldquo;거짓으로 표시된 전화번호로 인한 피해 예방 등에 관한 고시&rdquo;(이하 &ldquo;고시&rdquo;)에 따라 발신번호 사전등록서비스를 제공 및 운영합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style='font-family:"Cambria Math","serif";color:#111111;'>⑱</span><span style="color:#111111;">&nbsp;</span><span style="color:#111111;">이용자가 전기통신사업법 제84조의2&nbsp;제1항을 위반하여 발신번호를 변작하는 등 거짓으로 표시하는 경우,&nbsp;회사는 해당 메시지의 전송을 차단할 수 있습니다.&nbsp;단,&nbsp;회사는 전단의 메시지 전송을 차단한 후 지체 없이 당해 차단 사실을 이용자에게 통지합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style='font-family:"Cambria Math","serif";color:#111111;'>⑲</span><span style="color:#111111;">&nbsp;</span><span style="color:#111111;">본 조 제18항의 경우 회사는 차단된 메시지에 관한 자료(변작된 발신번호,&nbsp;차단시각,&nbsp;전송자명 등)를&nbsp;1년간 보관&middot;관리하고 이를 한국인터넷진흥원에 제출할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style='font-family:"Cambria Math","serif";color:#111111;'>⑳</span><span style="color:#111111;">&nbsp;</span><span style="color:#111111;">회사는 이용자가 발송하는 메시지의 발신번호가 블랙리스트(불법 전화번호 목록)에 포함된 번호인 경우 해당 메시지의 발송 또는 전달을 차단할 수 있습니다.&nbsp;단,&nbsp;회사는 전단의 메시지 발송을 차단한 후 지체 없이 당해 차단 사실을 이용자에게 통지합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제11조&nbsp;(이용자의 의무)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 이용자는 회사가 제공하는 서비스를 이용함에 있어 다음 각 호에 해당하는 행위를 하여서는 안됩니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용신청이나 변경 시 타인의 정보 또는 허위내용을 등록하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">다른 이용자의&nbsp;ID&nbsp;및 비밀번호를 도용하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">회사의 동의 없이 회사의 서비스를 이용하여 자기 또는 타인에게 재산상의 이익을 발생시키는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">제3자에게 임의로 해당 서비스를 임대하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">5.&nbsp;</span><span style="color:#111111;">회사 및 타인의 명예를 손상시키거나 업무를 방해하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">6.&nbsp;</span><span style="color:#111111;">불법스팸을 전송하거나 이로 인하여 타인의 재산상에 손해를 발생시키는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">7.&nbsp;</span><span style="color:#111111;">서비스를 사용하여 사기 또는 국가기관 사칭을 목적으로 한 내용의 메시지를 발송하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">8.&nbsp;</span><span style="color:#111111;">정보통신망법 제44조의7(불법정보의 유통금지 등)&nbsp;규정에 따라 다음 각 목의 내용을 서비스를 사용하여 직접 발송하거나 이용자의 고객이 발송하도록 방조하는 행위</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">가.&nbsp;음란한 부호&middot;문언&middot;음향&middot;화상 또는 영상을 배포&middot;판매&middot;임대하거나 공공연하게 전시하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">나.&nbsp;사람을 비방할 목적으로 공공연하게 사실이나 거짓의 사실을 드러내어 타인의 명예를 훼손하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">다.&nbsp;공포심이나 불안감을 유발하는 부호&middot;문언&middot;음향&middot;화상 또는 영상을 반복적으로 상대방에게 도달하도록 하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">라.&nbsp;정당한 사유 없이 정보통신시스템,&nbsp;데이터 또는 프로그램 등을 훼손&middot;멸실&middot;변경&middot;위조하거나 그 운용을 방해하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">마.&nbsp;청소년보호법에 따른 청소년 유해 매체물로서 상대방의 연령 확인,&nbsp;표시의무 등 법령에 따른 의무를 이행하지 아니하고 영리를 목적으로 제공하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">바.&nbsp;법령에 따라 금지되는 사행행위에 해당하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">사.&nbsp;정보통신망법 또는 개인정보 보호에 관한 법령을 위반하여 개인정보를 거래하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">아.&nbsp;총포&middot;화약류(생명&middot;신체에위해를 끼칠 수 있는 폭발력을 가진 물건을 포함한다)를 제조할 수 있는 방법이나 설계도 등의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">자.&nbsp;법령에 따라 분류된 비밀 등 국가기밀을 누설하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">차.&nbsp;국가보안법에서 금지하는 행위를 수행하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:138.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">카.&nbsp;그 밖에 범죄를 목적으로 하거나 교사(敎唆)&nbsp;또는 방조하는 내용의 정보</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">9.&nbsp;</span><span style="color:#111111;">회사의 동의 없이 회사의 서비스를 이용하여 얻은 정보를 복사,&nbsp;복제,&nbsp;변경,&nbsp;번역,&nbsp;출판,&nbsp;방송 및 기타의 방법으로 사용하거나 이를 타인에게 제공하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">10.&nbsp;</span><span style="color:#111111;">전기통신사업법 제84조의2&nbsp;제1항을 위반하여 메시지 전송 시 발신번호를 변작하는 등 거짓으로 표시하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">11.&nbsp;</span><span style="color:#111111;">타인의 발신번호를 도용하여 부정하게 사용하는 행위</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">12.&nbsp;</span><span style="color:#111111;">이용자가 서비스 특성상 회사,&nbsp;통신사 또는 &ldquo;카카오&rdquo;에 사전 신청한 전송 내용 또는 전송 용도를 벗어나는 전송 행위</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 이용자는 이용자의 정보통신설비가 서비스&nbsp;ID도용,&nbsp;해킹,&nbsp;바이러스 침입 등으로 불법스팸 발송에 오남용 되지 않도록 다음 각 호와 같은 보안조치를 취하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">정보통신망의 안정성 및 정보통신설비 보호를 위해 외부와 연결된 주요 네트워크 구간에 침입차단,&nbsp;침입탐지 등의 정보보호 시스템을 설치 및 운영합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">정보통신설비에 대한 접근은 인가된 접속지로부터 인가된 자만 접근할 수 있도록 하는 등 적절한 접근제어를 적용하여 운영합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">서비스&nbsp;ID&nbsp;도용,&nbsp;해킹,&nbsp;바이러스 침입 등의 보안사고를 예방하고 사고 발생 시 피해를 최소화하기 위해 정보통신설비의 환경설정 및 취약점을 주기적으로 점검하고 발견된 취약점을 제거하고 보안조치를 마련합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">정보통신설비에 대한 침입시도,&nbsp;내부 사용자 중 이상징후 발견 등을 위해 로그관리 계획을 수립하고 이행하며,&nbsp;로그는 최소&nbsp;6개월 동안 유지 및 관리합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">5.&nbsp;</span><span style="color:#111111;">이용중인 서비스&nbsp;ID&nbsp;및 비밀번호에 대한 관리 감독을 철저히 하여 제3자가 이용하지 못하도록 하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 이용자는 관련법 및 본 약관의 규정을 비롯하여 회사가 통지하는 사항 등을 준수하여야 하며,&nbsp;회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 이용자는 회사의 서비스를 이용하여 불법스팸을 전송하여서는 안 되며,&nbsp;불법스팸을 전송하는 경우 서비스 이용에 제한을 받을 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 이용자는 정보통신망법에 따라 메시지 전송을 위하여 수신자의 사전 수신동의를 직접 얻어야 하고,&nbsp;불법스팸 전송을 위해 서비스를 이용하여서는 안 됩니다.&nbsp;회사를 통해 전달되는 수신거부 요청에 대해서는 즉각적으로 처리하고&nbsp;24시간 이내에 처리결과를 회사에 회신하여야 하며 본 항의 위반으로 발생하는 모든 민형사상의 책임은 이용자가 직접 부담합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 이용자는 재화 및 용역의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한 경우에는 광고성 정보를 발송할 수 있는 일부 서비스에 한하여 수신자의 사전 동의 없이 이용자가 취급하는 재화 및 용역에 대한 영리목적의 광고성 정보를 전송할 수 있습니다.&nbsp;다만,&nbsp;이 경우의 기존거래관계는 광고수신일자로부터 최근&nbsp;6개월 이내에 거래관계가 있는 경우에만 인정합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 이용자는 수신자가 발신자를 정확하게 식별할 수 있는 발신 프로필을 사용하여야 하며 해당 사항에 틀림이 없는 지 지속적으로 확인해야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑧ 이용자는 불법적인 방법으로 확보한 전화번호를 사용하여 메시지 발송 시도를 하지 않아야 하며,&nbsp;다음 각 호의 사항을 수신자의 전화번호 수집 시점에 공지 및 기 수집된 전화번호의 수신자 대상 별도 공지를 통해 수신자에게 전달할 수 있어야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">서비스를 활용한 메시지 유형 중 알림톡의 수신 과정에서 와이파이 환경이 아닌 경우 데이터통신요금 발생 가능성이 있다는 사실</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">일림톡 수신을 원치 않을 경우 발신자의 고객센터 연락처 등을 통하여 발신자에게 수신 거부 의사를 전달할 수 있다는 내용</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑨ 이용자는 전기통신사업법 제84조의2&nbsp;등 관련 법령에 따라 발신번호 사전등록을 위한 기술적 관리적 조치를 완료하고 이에 따라 사전에 등록받은 발신 번호로만 메시지를 발송해야 합니다.(단,&nbsp;전기통신사업법 등의 관련 법률에서 허용한 예외의 경우는 그렇지 아니합니다.)&nbsp;전단의 조치에는 다음 각 호의 사항이 포함됩니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용계약 체결 시 재직 중인 임직원 수 제출 및 한국인터넷진흥원 지침에 따른 최대 허용 개수 이내로 발신번호 사전 등록</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">전 제1호의 최대 허용 개수를 초과하여 발신번호를 등록하고자 할 경우,&nbsp;회사가 관련 법령 및 한국인터넷진흥원 지침에 의거하여 요구하는 서류 제출 및 관련 절차 준수</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑩ 이용자는 계약 목적 외의 용도로 서비스를 이용하여서는 안 되며,&nbsp;제3자에게 서비스를 임대할 경우 제21조를 준수하여야 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제12조&nbsp;(이용자의 책임)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 이용자는 서비스 이용을 위한 ID&nbsp;및 비밀번호의 관리에 대한 책임,&nbsp;본인&nbsp;ID의 제3자에 의한 부정사용 등 이용자의 고의 또는 과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 이용자에게 통보된 서비스&nbsp;ID&nbsp;또는 비밀번호에 의하여 발생되는 제3자에 의한 부정사용 또는 회사의 의사와 무관한 서비스 사용상의 불법행위 내지 과실에 대한 모든 책임은 이용자에게 있습니다.&nbsp;다만,&nbsp;&ldquo;회사&rdquo;의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 이용자는&nbsp;ID,&nbsp;비밀번호 등을 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 비밀번호를 수정하는 등의 조치를 취하여야 하며,&nbsp;이를 회사에 통보하여 회사의 안내를 따라야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 제3항의 경우에 해당 이용자가 회사에 그 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 이용자는 제3자에게 서비스 재임대시 불법스팸 전 송으로 계약이 해지된 제3자가 명의변경 후 재가입하는 행위를 금지하기 위한 관리.감독의 의무를 이행합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 이용자는 과학기술정보통신부,&nbsp;한국인터넷진흥원 등에서 이용자로부터 발생한 불법스팸에 대해 필요 조치를 요구할 경우 그를 지체없이 이행합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 이용자는 메시지 발송을 위해 이용자가 운영하는 어플리케이션,&nbsp;웹서버, DB서버,&nbsp;네트웍 등에 대한 관리의 책임을 가지며,&nbsp;이용자의 관리 소홀 또는 시스템 취약점에 의한 해킹 또는 불법 침해로 인해 발생되는 서비스 중단,&nbsp;불법 스팸 발송 등의 피해에 대해서는 회사가 책임지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑧ 이용자는 영리목적의 광고성 정보 전송에 대한 수신자의 사전 수신 동의와 관련하여 수신자의 의사를 확인할 수 있는 자료를 관리하여야 하고 수신자와의 분쟁이 발생하는 경우,&nbsp;이를 입증할 책임이 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑨ 통신사 및 &ldquo;카카오&rdquo;의 정책에 따라 일부 서비스의 경우 이용자가 수신자의 사전 수신 동의를 얻은 경우에도 야간시간(오후&nbsp;9시&nbsp;~&nbsp;익일 오전&nbsp;9시,&nbsp;카카오의 경우 오후&nbsp;8시&nbsp;50분&nbsp;~&nbsp;익일 오전&nbsp;8시)&nbsp;동안 영리목적의 광고성 정보 전송이 제한될 수 있음에도 불구하고,&nbsp;이용자가 당해 시간에 영리목적의 광고성 정보를 전송하는 경우 그에 관한 책임을 부담하여야 하며 회사는 이용자의 당해 행위로 인하여 발생되는 서비스 중지 또는 전송 실패 등으로 인한 피해에 대해서 책임지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제4장 이용자의 권리에 관한 조치</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'>제13조&nbsp;(불만형태별 처리절차 및 처리기간)</h3>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";'>
    <ul style="margin-bottom:0cm;list-style-type: undefined;margin-left:59px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";'><span style='font-family:"맑은 고딕";font-size:10.0pt;'>&nbsp;</span><span style='font-family:"맑은 고딕";'>회사는 아래의 전화,&nbsp;전자우편 등을 통하여 이용자의 불만사항을 접수합니다.</span></li>
    </ul>
</div>
<p style='margin-right:0cm;margin-left:69.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'>&nbsp;1.&nbsp;불만처리 접수 전화: 02-8400-2613<br>&nbsp; 2.&nbsp;불만처리 접수 전자우편:<a href="mailto:message.to-go@sk.com">message.to-go@sk.com</a></p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";'>
    <ul style="margin-bottom:0cm;list-style-type: undefined;margin-left:59px;">
        <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";'><span style='font-family:"맑은 고딕";'>불만사항 처리는 접수 방법에 따라 운영자가 직접 전화,답변하여 처리하는 것을 원칙으로 하고,불만형태별 처리기간을 정하여 그 기간내에 처리한다.</span></li>
    </ul>
</div>
<table style="border: none;width:436.6pt;margin-left:9.7pt;border-collapse:collapse;">
    <tbody>
        <tr>
            <td style="width:62.0pt;border:solid windowtext 1.0pt;background:#D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>불만형태</p>
            </td>
            <td style="width:73.0pt;border:solid windowtext 1.0pt;border-left:none;background:  #D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>유형</p>
            </td>
            <td style="width:122.0pt;border:solid windowtext 1.0pt;border-left:none;background:  #D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>원인</p>
            </td>
            <td style="width:124.0pt;border:solid windowtext 1.0pt;border-left:none;background:  #D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>처리절차</p>
            </td>
            <td style="width:55.6pt;border:solid windowtext 1.0pt;border-left:none;background:  #D9D9D9;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>처리기간</p>
            </td>
        </tr>
        <tr>
            <td rowspan="3" style="width:62.0pt;border:solid windowtext 1.0pt;border-top:none;padding:  0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>서비스 관련</p>
            </td>
            <td style="width:73.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>통신 장애</p>
            </td>
            <td style="width:122.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>회사의 귀책사유</p>
            </td>
            <td style="width:124.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>대고객 사과 및 품질개선</p>
            </td>
            <td style="width:55.6pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>3일 이내</p>
            </td>
        </tr>
        <tr>
            <td rowspan="2" style="width:73.0pt;border-top:none;border-left:  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>시스템 장애</p>
            </td>
            <td style="width:122.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>회사의 귀책사유</p>
            </td>
            <td style="width:124.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>대고객 사과 및 품질개선</p>
            </td>
            <td style="width:55.6pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>3일 이내</p>
            </td>
        </tr>
        <tr>
            <td style="width:122.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>이용자(회원)의 귀책사유</p>
            </td>
            <td style="width:124.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>대고객 설명</p>
            </td>
            <td style="width:55.6pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>1일 이내</p>
            </td>
        </tr>
        <tr>
            <td rowspan="3" style="width:62.0pt;border:solid windowtext 1.0pt;border-top:none;padding:  0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>요금 관련</p>
            </td>
            <td rowspan="3" style="width:73.0pt;border-top:none;border-left:  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>청구요금 이의</p>
            </td>
            <td rowspan="2" style="width:122.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>회사의 귀책사유</p>
            </td>
            <td style="width:124.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>과금전&nbsp;:&nbsp;정정 과금 처리</p>
            </td>
            <td style="width:55.6pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>3일 이내</p>
            </td>
        </tr>
        <tr>
            <td style="width:124.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>과금후&nbsp;:&nbsp;환불 처리</p>
            </td>
            <td style="width:55.6pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>7일 이내</p>
            </td>
        </tr>
        <tr>
            <td style="width:122.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>이용자의 귀책사유</p>
            </td>
            <td style="width:124.0pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>대고객 설명</p>
            </td>
            <td style="width:55.6pt;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 4.95pt 0cm 4.95pt;height:17.25pt;">
                <p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:center;line-height:normal;'>1일 이내</p>
            </td>
        </tr>
    </tbody>
</table>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'>제14조&nbsp;(서비스 제공 불가 시 처리)</h3>
<ul style="list-style-type: undefined;margin-left:58px;">
    <li><span style='font-family:"맑은 고딕";font-size:10.0pt;'>&nbsp;</span><span style='font-family:"맑은 고딕";'>서비스 제공이 불가능한 경우&nbsp;:&nbsp;천재지변,&nbsp;시스템(통신)&nbsp;장애 및 점검,&nbsp;서비스 양도 및 폐지(휴지)&nbsp;등</span></li>
    <li><span style='font-family:"맑은 고딕";'>천재지변 등을 제외한 서비스 제공이 불가능한 경우 이용자(고객)에게 사전 공지(안내)를 한다.</span></li>
    <li><span style='font-family:"맑은 고딕";'>천재지변 등을 제외한 서비스 제공이 불가능한 경우 이용자(회원)에게 사전 공지(안내)&nbsp;없이 서비스를 중단 하였을 시,&nbsp;그로 인해 피해가 발생한 이용자(회원)에게 피해에 따른 손해를 확인 후 보상할 수 있다.</span></li>
    <li><span style='font-family:"맑은 고딕";'>회사는 사업의 전부 및 일부를 휴지 또는 폐지 하고자 할 때에는 그 휴지 또는 폐지 예정일의&nbsp;30일전까지 그 내용을 이용자(고객)에게 이메일,&nbsp;홈페이지 등을 이용하여 공지한다.</span></li>
</ul>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제5장 서비스의 이용</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제15조&nbsp;(서비스의 제공 및 제한)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 이용자에게 아래와 같은 서비스를 제공합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">문자메시지&nbsp;(대량)&nbsp;발송 서비스</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">멀티미디어메시지&nbsp;(대량)&nbsp;발송 서비스</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">알림톡 및 친구톡&nbsp;(대량)&nbsp;발송 서비스</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">5.&nbsp;</span><span style="color:#111111;">알림톡 및 친구톡 발송 실패 시 문자메시지,&nbsp;멀티미디어메시지 대체 발송 서비스</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">6.&nbsp;</span><span style="color:#111111;">기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 이용자에게 제공할 일체의 서비스</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 제1항의 각 서비스 및 제휴 서비스의 내용은 변경 될 수 있으며,&nbsp;이 경우 회사는 서비스 홈페이지에 공지하거나 이용자가 이용신청 시 등록한 이메일로 이용자에게 통지 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 서비스는 연중무휴&nbsp;1일&nbsp;24시간 제공함을 원칙으로 합니다.&nbsp;단,&nbsp;회사는 서비스 시스템의 정기점검을 월&nbsp;8시간 내에서 실시할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사는 이용자에게 다음 각 호와 같이 서비스 이용에 필요한 시스템(모듈)을 제공하며,&nbsp;이용자는 회사와 별도 협의하여 연동방식을 선택할 수 있습니다.&nbsp;단,&nbsp;서비스 유형에 따라 이용자가 선택할 수 있는 연동방식이 제한될 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1. DB</span><span style="color:#111111;">서버 연동방식</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">웹 발송방식</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3. REST API&nbsp;</span><span style="color:#111111;">연동방식</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 회사는 국가비상사태,&nbsp;서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 회사는 이용자가 다음 각 호에 해당할 경우&nbsp;1개월 동안의 기간을 정하여 이용자의 서비스 이용을 즉시 정지(일부 또는 전체에 대하여)&nbsp;할 수 있으며,&nbsp;이용정지 등의 원인이 된 사유가 완전히 해소되기 전까지는 이용정지 등 조치를 해제하지 아니할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용자가 서비스를 불법스팸 전송에 이용하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">이용자가 전송하는 광고로 인하여 회사의 서비스에 장애를 야기하거나 야기할 우려가 있을 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">이용자가 전송하는 광고의 수신자가 불법스팸으로 신고한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">이용자가 제23조의 이용요금을 연체하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">5.&nbsp;</span><span style="color:#111111;">이용자가 불법스팸을 전송하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">6.&nbsp;</span><span style="color:#111111;">이용자가 전송한 메시지가 불법스팸으로 신고되는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">7.&nbsp;</span><span style="color:#111111;">전송한 광고성 정보가 불법스팸임이 확인된 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">8.&nbsp;</span><span style="color:#111111;">불법스팸 전송으로 과태료가 부과된 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">9.&nbsp;</span><span style="color:#111111;">회사에게 증빙서류 등을 통해 불법스팸 전송이 아님을 확인하여 주지 않고&nbsp;1일&nbsp;500건을 초과하는 메시지를 전송하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">10.&nbsp;</span><span style="color:#111111;">전기통신사업법 제30조를 위반하여 제3자에게 서비스를 이용하도록 제공한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">11.&nbsp;</span><span style="color:#111111;">스팸발송에 이용되거나 악성 앱에 감염되어 스팸을 전송한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">12.&nbsp;</span><span style="color:#111111;">회사의 수신거부 요청 처리에 불성실하여 수신거부 요청 건수가 감소되지 않거나 발송금지를 요청한 메시지 내용이 중복적으로 발송될 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">13.&nbsp;</span><span style="color:#111111;">방송통신위원회,&nbsp;과학기술정보통신부 또는 한국인터넷진흥원 등 관련 기관이 불법스팸의 전송 사실을 확인하여 이용정지를 요청하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">14.&nbsp;</span><span style="color:#111111;">과학기술정보통신부 또는 한국인터넷진흥원 등 관련 기관이 발신번호 변작 등을 확인하여 이용정지를 요청하는 경우</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 회사는 이용자의 서비스 이용 내용이 다음 각호에 해당할 경우 그 이용을 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용자가 제11조 및 본 약관상의 의무를 위반하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">이용자가 회사의 서비스 운영을 고의 또는 과실로 방해하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">회사에 납부할 서비스 이용금액(미납요금과 미청구 금액)이 담보한 보증금액을 초과한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">카카오톡 또는 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 정보통신설비의 오동작 또는 시스템 보유 정보 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">5.&nbsp;</span><span style="color:#111111;">정보통신망법,&nbsp;전기통신사업법 등 관련법령,&nbsp;카카오톡 채널 서비스 운영정책을 위반한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">6.&nbsp;</span><span style="color:#111111;">타인의 지적재산권을 침해하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">7.&nbsp;</span><span style="color:#111111;">방송통신심의위원회의 시정요구가 있거나,&nbsp;불법선거운동과 관련하여 선거관리위원회의 유권 해석을 받은 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">8.&nbsp;</span><span style="color:#111111;">타인의 서비스 사용 권한을 부정하게 이용하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">9.&nbsp;</span><span style="color:#111111;">서비스 정보를 이용하여 얻은 회사의 시스템 관련 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">10.&nbsp;</span><span style="color:#111111;">타인의 명예를 손상시키거나 불이익을 주는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">11.&nbsp;</span><span style="color:#111111;">서비스에 위해를 가하거나 서비스의 건전한 이용을 저해하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">12.&nbsp;</span><span style="color:#111111;">회사의 채권보전조치를 위해 제출을 요구한 증빙서류를 허위로 작성한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">13.&nbsp;</span><span style="color:#111111;">국익 또는 사회적 공익을 저해할 목적으로 이용하는 경우</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑧ 회사는 불법스팸 발송을 방지하기 위하여 해당&nbsp;ID가&nbsp;6개월간 서비스 이용 내역이 없는 경우 해당&nbsp;ID의 서비스 이용을 정지할 수 있습니다.&nbsp;단,&nbsp;서비스 이용 정지 기간은 이용자가 해당&nbsp;ID의 이용 정지 해제를 요청하는 기간까지로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑨ 회사는 불법스팸을 전송하였다고 확인이 된 계약자가 이용 중인 다른 번호가 불법스팸에 악용되고 있거나 악용될 우려가 있다는 객관적 정황이 있는 경우에는 그 다른 번호도 이용 정지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑩ 회사는 본 조 제6항 내지 제9항의 규정에 의하여 서비스를 정지하는 경우,&nbsp;그 사실을 이용자에게 서면 통보하여야 하며,&nbsp;다만 사전에 통보하는 것이 곤란할 경우 이용 정지 조치 후 통지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑪ 회사는 컴퓨터 등 정보통신설비의 정기점검,&nbsp;교체 및 고장,&nbsp;통신두절 또는 기술상ㆍ운영상 상당한 이유가 있는 경우 서비스 홈페이지에 공지하거나 이용자가 이용신청 시 등록한 이메일로 이용자에게 통지한 후 서비스의 제공을 일시적으로 중단할 수 있습니다.&nbsp;다만,&nbsp;회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 사후에 통지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑫ 회사의 귀책사유가 없는 서비스 장애로 인하여 이용자 또는 이용자의 고객에게 손해가 발생한 경우 회사는 이에 대해 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑬ 이용자는 서비스의 정상적인 사용에 기술적 문제가 발생하였을 경우 이용자 또는 이용자의 고객 시스템에 장애가 없는 것을 확인한 후 회사에 고장 신고합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑭ 회사는 전항에 의한 고장 신고를 받아 이를 처리한 후 그 결과를 이용자에게 통지합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑮ 사업종목의 전환,&nbsp;사업의 포기,&nbsp;업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 서비스 홈페이지에 공지 및 이용자가 이용신청 시 등록한 이메일로 이용자에게 통지한 후 서비스를 종료할 수 있습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제16조&nbsp;(정보의 제공)</span></h3>
<p style='margin-right:0cm;margin-left:0cm;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:justify;margin:0cm;background:white;'><span style='font-size:13px;font-family:"맑은 고딕";color:#111111;'>회사는 이용자가 서비스 이용 중 필요하다고 인정되는 경우 다양한 정보를 공지사항이나 이메일 등의 방법으로 이용자에게 제공할 수 있습니다.&nbsp;다만,&nbsp;이용자는 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 이메일에 대해서 수신 거절을 할 수 있습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제17조&nbsp;(문자 발송량 제한)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 정보통신망법에서 금지하는 대량의 불법스팸 전송을 방지하기 위하여 개인 이용자의 아이디 당&nbsp;1일&nbsp;500건을 초과하는 메시지(SMS, MMS, LMS&nbsp;포함)를 전송할 수 없습니다.&nbsp;단,&nbsp;이용자가 제출하는 증빙서류 등을 통해 회사가 불법스팸 전송이 아님을 확인할 수 있는 경우 초과 전송이 가능합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 이용자가 제출한 증빙서류 등을 통해 불법스팸 전송이 아님을 확인하였더라도 이용자의 메시지(SMS, MMS, LMS&nbsp;포함)&nbsp;발송량이 과다할 경우 회사는 발송을 제한할 수 있습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제18조&nbsp;(권리의 귀속)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 이용자는 서비스에 관하여 사용권을 부여받으며 이를 양도,&nbsp;증여,&nbsp;질권의 목적으로 사용할 수 없습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사 또는 이용자가 상대방의 지적재산권을 침해하여 손해가 발생한 경우 그 손해를 배상하여야 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제19조&nbsp;(계약사항의 변경)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 이용자는 계약사항 중 다음 각호의&nbsp;1에 해당하는 사항을 변경하고자 하는 때에는 서비스 변경신청서를 회사에 제출하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용자의 상호,&nbsp;성명 또는 주소의 변경</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">서비스의 종류 및 이용계약기간의 변경</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">클라이언트의 설치장소 및 환경 변경</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 다음 각호의&nbsp;1에 해당하는 경우에는 제&nbsp;1항의 규정에 의한 신청을 제한할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">회사가 제공하는 특정 모듈 및&nbsp;DB&nbsp;스키마를 설치함에 있어 시스템 등의 신설,&nbsp;개조 또는 수리가 기술상 현저히 곤란한 때</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">서비스를 제공하기 위하여 필요한 설비가 없거나 기 설치된 설비에 여유가 없을 때</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">이용자가 이용요금을 체납하고 있을 때</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 제&nbsp;1항 제&nbsp;2호에 의하여 이용계약이 변경된 경우에는 새로운 이용계약에 의하여 요금이 적용됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 이용자가 변경사항에 대하여 회사에 신고하지 아니함으로 인하여 이용자에게 발생하는 불이익은 모두 이용자가 부담합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 정기이용계약 만료&nbsp;30일전까지 새로운 이용계약의 체결 또는 이용계약의 해지에 대하여 이용자 또는 회사의 특별한 의사표시가 없을 경우 이전의 이용계약과 동일한 조건 및 동일한 이용계약기간으로 자동연장 되는 것으로 봅니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 상속,&nbsp;합병,&nbsp;분할,&nbsp;영업양수 등으로 이용자의 지위승계 사유가 발생한 때에는 그 사유가 발생한 날로부터&nbsp;1개월 이내에 사업자등록증사본(법인에 한합니다)과 지위승계를 입증할 수 있는 관련서류를 첨부하여 회사에 신고하여야 합니다.&nbsp;단,&nbsp;영업양수 등 거래관계 지위가 당연히 승계되지 않는 경우 이용자는 회사로부터 별도의 동의를 얻어야 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제20조&nbsp;(이용자의 계약의 해제 및 해지)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 이용자는 다음 각 호의 사유가 있는 경우에는 이용계약을 해제 또는 해지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용자가 서비스 이용을 원하지 않는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">서비스의 하자를 회사가 보완,&nbsp;수정할 수 없는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">이용자가 약관의 변경에 동의하지 않는 경우</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 본 조의 계약해제 및 해지는 이용자가 회사에 의사를 표시한 때에 효력이 발생합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 이용자가 이용계약의 해지 또는 해제를 원하는 경우 아래의 전화 또는 이메일 또는 홈페이지를 통하여 신청할 수 있으며,&nbsp;이후 회사가 안내하는 절차를 이행해주시면 됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">&middot; 연락처:02-6400-2611, message.to-go@sk.com</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제21조&nbsp;(회사의 계약의 해제 및 해지)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 이용자가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우,&nbsp;명의도용,&nbsp;저작권법을 위반한 불법프로그램의 사용 및 운영방해,&nbsp;회사와 기타 제3자의 지적재산권에 대한 침해,&nbsp;회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작ㆍ배포ㆍ이용ㆍ광고,&nbsp;정보통신망법을 위반한 불법통신 및 해킹,&nbsp;악성프로그램의 배포,&nbsp;접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 계약을 해제 및 해지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 이용자가 다음 중 하나에 해당하는 경우 당해 서비스 계약의 전부 또는 일부를 즉시 해제하거나 해지할 수 있으며,&nbsp;그 사실을 이용자에게 통지합니다.&nbsp;이용자의 책임있는 사유로 통지할 수 없는 경우에는 통지된 경우로 간주합니다.&nbsp;다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우에는 지체없이 사후 통지로 대체할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">타인의 명의로 서비스 계약을 체결하였거나 서비스 계약 체결 시 제출한 자료 및 정보가 허위 또는 누락 되었음이 확인된 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">회사의 서비스 제공 목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">방송통신위원회 또는 한국인터넷진흥원장이 불법스팸의 전송사실을 확인하여 회사에게 서비스 계약 해지를 요청하는 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">전기통신사업법 제30조에 위반하여 제3자에게 해당 서비스를 이용하도록 제공한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">5.&nbsp;</span><span style="color:#111111;">불특정 다수를 대상으로 메시지(SMS, MMS&nbsp;포함)&nbsp;등의 방법을 통해 무차별적으로 스팸을 전송한 사실이 확인된 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">6.&nbsp;</span><span style="color:#111111;">이용자가 전송한 광고성 정보에 대해 회사가 한국인터넷진흥원에 불법 스팸 유무확인을 요청하여 불법스팸임이 확인된 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">7.&nbsp;</span><span style="color:#111111;">수신처를 자동으로 생성하여 불특정 다수에게 광고성 메시지(SMS, MMS&nbsp;포함)를 전송한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">8.&nbsp;</span><span style="color:#111111;">전송자를 확인하지 못하게 할 목적으로 발신번호를 변작하여 영리목적의 광고성 정보를 전송한 사실이 확인된 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">9.&nbsp;</span><span style="color:#111111;">제14조 규정에 의하여 이용정지 또는 제한의 사유가&nbsp;1년 이내에 재발한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">10.&nbsp;</span><span style="color:#111111;">제14조 규정에 따른 이용정지 또는 제한 기간 내에 이용정지 사유를 해소하지 않고&nbsp;1개월이 경과된 경우</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사는 불법스팸을 전송하였다고 확인된 계약자가 이용 중인 다른 번호가 불법스팸에 악용되고 있거나 악용될 우려가 있다는 객관적 정황이 있는 경우에는 그 다른 번호에 대한 서비스도 해지할 수 있습니다</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사는 제1항 내지 제3항의 규정에 의하여 서비스 계약을 해지하는 경우,&nbsp;그 사실을 당해 이용자에게 통지합니다.&nbsp;고객의 책임있는 사유로 통지 할 수 없는 경우에는 통지된 것으로 간주됩니다.&nbsp;미리 통지하는 것이 곤란한 경우에는 서비스 계약 해지 후 통지 할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 회사는 제1항 내지 제3항의 규정에 의하여 서비스 이용계약을 해제 또는 해지한 이용자에 대하여&nbsp;1년간 서비스 이용신청에 대한 승낙을 유보하기 위하여 성명,&nbsp;법인등록번호,&nbsp;사업자등록번호,&nbsp;전화번호,&nbsp;해지사유 등의 정보를&nbsp;1년간 보관할 수 있습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제22조&nbsp;(이용자가 제3자에게 서비스 임대 시 준수사항)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사 서비스를 임대 받은 제3자가 개인 이용자를 유치할 경우 이용자의&nbsp;ID당 문자 전송량을&nbsp;1일&nbsp;500통으로 제한합니다.&nbsp;다만,&nbsp;적법한 업무용 광고 발송,&nbsp;동창회 연락 등 이용자 불편을 최소화 하기 위하여 사전승인을 얻은 경우 발송량 제한 없이 발송이 가능하도록 예외를 인정할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사 서비스를 임대 받은 제3자의 정보통신설비가 해킹,&nbsp;바이러스 침입 등으로 스팸 발생에 오남용 되지 않도록 제11조 제2항의 보안조치를 취하도록 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사 서비스를 임대 받은 제3자가 제4조 제2항 각 호에 따라 이용신청이 이루어지지 않는지 관리&middot;감독합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사 서비스를 임대받은 제3자가 유치한 이용고객이 불법스팸을 발송했을 경우 제14조 및 제20조에 의해 이용정지 및 서비스 계약을 해지합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 회사 서비스를 임대 받은 제3자의 정상적인 서비스 이용을 위하여 본 약관 상 이용자에게 부여된 의무를 준수하도록 제3자를 관리&middot;감독하여야 하며 이와 관련하여 분쟁이 발생하거나 회사 또는 제3자에게 손해가 발생하는 경우 이용자가 모든 책임을 부담하여야 하며 회사는 면책됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 본 조와 관련하여 이용자 또는 제3자와 분쟁이 발생한 경우 회사는 면책되며 이용자가 모든 민&middot;행&middot;형사상 책임을 부담합니다</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:8.0pt;text-align:justify;'><span style="font-size:22px;line-height:107%;">&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제6장 요금 등</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제23조&nbsp;(이용요금의 종류 및 계산방법)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 서비스 이용과 관련하여 이용자가 납입하여야 할 요금의 종류는 다음과 같습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">커스터마이징&nbsp;(Customizing)비:&nbsp;회사가 제공하는 서비스를 개별 고객의 기업 특성에 맞추어 제공하는데 소요되는 비용</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">이용요금:&nbsp;서비스를 이용하는 대가로 월 기본료(기본료를 납부하는 이용자에 한함)와 함께 이용자가 회사가 사전에 약정한 단가와 발송 성공건수를 적용하여 산정되는 이용료</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">월 최저이용료:&nbsp;서비스를 이용하는 고객에 대한 서비스 단가의 할인 적용 시,&nbsp;이용자가 매월 최소한 납부하여야 하는 이용요금</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 제&nbsp;1항의 규정에 의한 요금의 계산방법은 다음과 같습니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">커스터마이징&nbsp;(Customizing)비:&nbsp;서비스의 이용승낙 또는 변경을 요청 받은 경우 회사와 이용자간 사전 합의에 의해 정합니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">이용요금:&nbsp;매월&nbsp;1일부터 말일까지&nbsp;1개월간 이용자가 발송한 메시지 성공건수에 따라 계산되며,&nbsp;여기에서 성공건수는 수신자가 속한 이통사에서 전달하는 성공값을 기준으로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 승인월 또는 해지월의 경우 이용기간이&nbsp;1개월 미만인 경우에는 기본료를 제외한 해당월 사용한 발송량에 해당하는 이용요금을 청구 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 제18조 제3항에 의거하여 서비스 이용요금이 변경되는 경우 익월&nbsp;1일부터 변경된 요금이 적용됩니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제24조(이용요금의 청구 및 납입)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 해당월(M월)의 이용요금을 익월(M+1월) 10일까지 이용자에게 청구하고,&nbsp;이용자는 익월(M+1월)&nbsp;말일까지 회사가 청구한 이용요금을 납부하여야 합니다.&nbsp;단,&nbsp;이용자가 이용요금의 납부 방식으로 자동이체를 선택한 경우,&nbsp;해당월(M월)의 이용요금은 익월(M+1월) 17일&nbsp;~ 21일 사이에 이용자가 지정한 계좌에서 자동 인출되는 것으로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 제1항에도 불구하고 이용자와 회사가 사전 서면 합의하는 경우,&nbsp;이용자의 서비스 이용요금 지급일자를 달리 정할 수 있습니다.&nbsp;단,&nbsp;이 경우 이용자가 회사에 제공해야 하는 담보금액이 증액될 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 이용자와 회사의 정산 자료에 상호&nbsp;2%&nbsp;및 일금 오만원 이내의 차이가 있는 경우 회사의 정산자료를 기준으로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사가 제공하는 서비스에 대한 이용요금은 계좌이체,&nbsp;무통장입금의 방법으로 결제할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 이용자는 회사가 동의하는 경우 서비스 이용요금의 납부시기 및 방법을 변경할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 이용자는 서비스에 대한 이용요금을 성실히 납부하여야 하며,&nbsp;이용요금 납부의 지연 시에는 연 이율&nbsp;12%의 지연이자를 회사에 지급하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 카카오가 카카오톡 비즈메시지의 단가를 변경하거나 통신사의 통신비 변경 시 회사 또는 이용자는 상호간의 이용요금의 변경을 요청할 수 있으며,&nbsp;이 경우 상호 협의하여 이용요금을 결정합니다.&nbsp;단,&nbsp;양 당사자간 요구한 이용요금 변경에 대하여 회사 또는 이용자가 동의하지 않을 경우 회사 또는 이용자는 계약 해지&nbsp;30일 전에 서면통보하고 이용계약을 해지할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑧ 이용자는 이용자의 관리 소홀로 불법스팸이 발송될 경우 이에 대한 이용요금을 자신의 비용으로 모두 부담합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑨ 회사는 이용자의 이용요금 체납이 본 조 제 1항의 납기일로부터&nbsp;10일을 초과할 경우 제14조 제6항 제4호에 의거 이용자의의 이용을 정지하고 독촉장을 발부할 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑩이용자는회사의 요구에 따라 월 이용요금의&nbsp;2배수(제2항의 규정에 따라 회사와 이용자의 사전 합의에 따라 이용자의 이용요금 지급일자를 달리 정한 경우에는 회사가 요청하는 금액)&nbsp;또는&nbsp;\1,000,000&nbsp;중 상위 금액에 해당하는 금액을 보험가입금액으로 하는 이행(지급)보증보험증권(이하&nbsp;&quot;증권&quot;)을 제출하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑪ 회사의 요구에도 불구하고 이용자가 증권을 미제출 할 경우 회사는 서비스 이용을 제한할 수 있습니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제25조&nbsp;(이용요금의 환불 및 이의제기)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 과오금이 발생한 경우 이용요금의 결제와 동일한 방법으로 과오금을 환불하여야 합니다.&nbsp;다만,&nbsp;동일한 방법으로 환불이 불가능한 경우에는 이를 사전에 고지합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 과오금을 전액 환불합니다.&nbsp;다만,&nbsp;이용자의 책임있는 사유로 과오금이 발생한 경우,&nbsp;회사가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사의 귀책사유가 있는 경우에는 환불수수료가 공제되지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 현행법령 및 중대한 약관 위반 등 이용자의 귀책사유로 이용계약을 해지하는 경우 환불이 제한될 수 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">&nbsp;</span></p>
<h2 style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="font-size:22px;color:#111111;">제7장 손해배상 등</span></h2>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제26조&nbsp;(손해배상의 범위 및 청구)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사와 이용자는 고의 또는 과실로 상대방에게 손해를 발생시킨 경우,&nbsp;당해 손해에 대하여 배상할 책임이 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">②이용자가 서비스 이용 중 회사의 귀책사유로 인하여 서비스가 중단되었을 경우 회사는 체결된 
    <a
      href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
      class="font-weight-bold"
      target="_blank"
      style="font-size: 0.9rem; text-decoration: underline;"
      >SLA약정</a
    >
에 근거해 보상합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 이용자가 본 약관 및 관련 법령을 위반하여 회사에 손해를 발생시킨 경우,&nbsp;이용자는 회사에 대하여 그 손해를 배상할 책임이 있습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 이용자는 불법스팸 전송 등 불법행위를 하거나 전기통신사업법 등 관련 법령을 준수하지 못해 발생하는 모든 민형사상의 책임을 부담하며,&nbsp;회사는 면책됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 이용자의 손해배상의 청구는 회사에 청구사유,&nbsp;청구금액 및 산출근거를 기재하여 서면으로 하여야 합니다.</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제27조&nbsp;(면책)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사는 이용자간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">③ 회사는 서비스 이용의 장애로 인하여 발생한 이용자의 부가적,&nbsp;영업적인 손해에 대해서는 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">④ 회사는 회사가 제공하는 무료서비스 이용과 관련하여 이용자에게 발생한 손해에 대해서는 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑤ 회사는 회사의 고의 또는 과실이 없이 이용자의 고의 또는 과실에 의하여서만 서비스의 장애가 발생한 경우에는 본 약관에서 정한 이용자에 대한 손해배상책임이 없으며 수신자의 손해에 대하여도 배상 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑥ 회사는 이용자가 서비스를 통하여 전송한 메시지의 내용에 대해서는 그 정확성,&nbsp;신뢰성,&nbsp;시기적절성 등을 보장하지 않으며 해당 내용이 관현 법령을 위반하거나 제3자의 권리를 침해하는 경우 이에 대한 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑦ 회사는 이용자의 대체 발송 기능 사용상의 부주의로 인한 이용자의 동일 내용의 중복 전송 또는 메시지 전송실패에 대하여 책임을 지지 않습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑧ 회사는 회사의 고의 또는 과실이 없는 한 서비스를 활용하여 발송된 메시지 관련 발신자와 수신자 간 분쟁에 대하여 개입할 의무가 없으며 이로 인한 손해를 배상할 책임이 없습니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑨ 회사는 제14조에 따라 이용자의 서비스 이용을 정지 또는 제한하는 경우,&nbsp;이용의 제한으로 발생할 수 있는 이용자의 손해 등에 대해서는 책임이 면제됩니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">⑩ 다음 각호&nbsp;1의 경우에는 회사에게 귀책사유가 없는 것으로 봅니다.</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">1.&nbsp;</span><span style="color:#111111;">이용자의 전산망 장애로 발생하는 서비스의 지연 및 불능의 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">2.&nbsp;</span><span style="color:#111111;">이용자의 지적재산권 침해 등에 의한 이용자의 고객 또는 제3자에게 손해가 발생한 경우</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">3.&nbsp;</span><span style="color:#111111;">회사의 서비스 시스템의 정기 점검 또는 업데이트로 인한 서비스의 제한</span></p>
<p style='margin-right:0cm;margin-left:102.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">4.&nbsp;</span><span style="color:#111111;">회사에게 회선,&nbsp;통신망,&nbsp;전용선망을 제공하고 있는 이동통신사 또는 부가통신사업자 측의 장애 귀책사유 또는 카카오 측의 장애&middot;귀책사유로 인한 서비스의 불완전 또는 불능으로 이용자 또는 제3자에게 야기된 손해</span></p>
<h3 style='margin-top:37.5pt;margin-right:0cm;margin-bottom:15.0pt;margin-left:50.0pt;text-align:justify;text-indent:-20.0pt;font-size:13px;font-family:"맑은 고딕";font-weight:normal;line-height:16.8pt;background:white;'><span style="color:#4394FE;">제28조&nbsp;(준거법 및 재판관할)</span></h3>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">① 회사와 이용자 간 제기된 소송은 대한민국법을 준거법으로 합니다.</span></p>
<p style='margin-right:0cm;margin-left:51.0pt;font-size:13px;font-family:"맑은 고딕";margin-top:0cm;margin-bottom:.0001pt;text-align:left;line-height:normal;background:white;'><span style="color:#111111;">② 회사와 이용자 간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 관할법원으로 합니다.</span></p>
  </section>



  <section class="py-5 position-relative">
    <div class="page-header min-vh-50 m-3 border-radius-xl" style="background-image: url('https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/curved-images/curved8.jpg');">
      <span class="mask bg-gradient-dark opacity-4"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center mx-auto">
            <h1 class="text-white mt-4 mb-1">질문이 있으신가요?</h1>
            <p class="lead text-white mb-6">저희 솔루션에 대해 더욱 자세히 설명드리겠습니다.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-n7 blur border-radius-lg shadow-blur">
        <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
          <div class="p-3 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>map-big</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-2321.000000, -593.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g id="map-big" transform="translate(605.000000, 302.000000)">
                        <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
                        <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
                        <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
                        <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <h5 class="mt-3 mb-1">주소</h5>
            <p class="mb-0">경기도 성남시 분당구 <br> 성남대로343번길 9 (SK u타워)</p>
            <hr class="vertical dark">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
          <div class="p-3 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>document</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g transform="translate(154.000000, 300.000000)">
                        <path d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
                        <path d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <h5 class="mt-3 mb-1">이메일</h5>
            <p class="mb-0"><br>message.to-go@sk.com</p>
          </div>
          <hr class="vertical dark">
        </div>
        <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
          <div class="p-3 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 44 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>megaphone</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-2168.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g id="megaphone" transform="translate(452.000000, 300.000000)">
                        <path d="M35.7958333,0.273166667 C35.2558424,-0.0603712374 34.5817509,-0.0908856664 34.0138333,0.1925 L19.734,7.33333333 L9.16666667,7.33333333 C4.10405646,7.33333333 0,11.4373898 0,16.5 C0,21.5626102 4.10405646,25.6666667 9.16666667,25.6666667 L19.734,25.6666667 L34.0138333,32.8166667 C34.5837412,33.1014624 35.2606401,33.0699651 35.8016385,32.7334768 C36.3426368,32.3969885 36.6701539,31.8037627 36.6666942,31.1666667 L36.6666942,1.83333333 C36.6666942,1.19744715 36.3370375,0.607006911 35.7958333,0.273166667 Z"></path>
                        <path d="M38.5,11 L38.5,22 C41.5375661,22 44,19.5375661 44,16.5 C44,13.4624339 41.5375661,11 38.5,11 Z" opacity="0.601050967"></path>
                        <path d="M18.5936667,29.3333333 L10.6571667,29.3333333 L14.9361667,39.864 C15.7423448,41.6604248 17.8234451,42.4993948 19.6501416,41.764381 C21.4768381,41.0293672 22.3968823,38.982817 21.7341667,37.1286667 L18.5936667,29.3333333 Z" opacity="0.601050967"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <h5 class="mt-3 mb-1">전화번호</h5>
            <p class="mb-0"><br>(02)6400-2613</p>
          </div>
          <hr class="vertical dark">
        </div>
        <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
          <div class="p-3 text-center">
            <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
              <svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>ungroup</title>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-2170.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <g transform="translate(1716.000000, 291.000000)">
                      <g id="ungroup" transform="translate(454.000000, 151.000000)">
                        <path d="M38.1818182,10.9090909 L32.7272727,10.9090909 L32.7272727,30.9090909 C32.7272727,31.9127273 31.9127273,32.7272727 30.9090909,32.7272727 L10.9090909,32.7272727 L10.9090909,38.1818182 C10.9090909,39.1854545 11.7236364,40 12.7272727,40 L38.1818182,40 C39.1854545,40 40,39.1854545 40,38.1818182 L40,12.7272727 C40,11.7236364 39.1854545,10.9090909 38.1818182,10.9090909 Z" opacity="0.6"></path>
                        <path d="M27.2727273,29.0909091 L1.81818182,29.0909091 C0.812727273,29.0909091 0,28.2781818 0,27.2727273 L0,1.81818182 C0,0.814545455 0.812727273,0 1.81818182,0 L27.2727273,0 C28.2781818,0 29.0909091,0.814545455 29.0909091,1.81818182 L29.0909091,27.2727273 C29.0909091,28.2781818 28.2781818,29.0909091 27.2727273,29.0909091 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <h5 class="mt-3 mb-1">영업 담당자</h5>
            <p class="mb-0"><br>박승현 매니저</p>
          </div>
        </div>
      </div>
      <div class="container">
        <br>
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="mb-4 col-lg-9 mb-lg-0">
              <div
                class="text-sm text-center copyright text-muted"
                :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
              >
              ©
          {{ new Date().getFullYear() }}, made
          <!-- <i class="fa fa-heart"></i>  -->
          by
          <a
            href="https://www.sk.com"
            class="font-weight-bold"
            target="_blank"
            >SK 주식회사 C&C</a
          >
              </div>
              <span style="font-size: 0.8rem; margin-right: 0.5rem;">
          SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
          </span>
            </div>
            <div class="col-lg-2">
              <ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>-->
  <!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/soft-ui-dashboard.min.js?v=1.0.2"></script>-->
  <!-- <script src="../assets/js/loopple/loopple.js"></script> -->
  </body>
  <!--  <script>-->
  <!--    document.addEventListener('DOMContentLoaded', function() {-->
  <!--      // Remove the "active" class from the afterpay tab-->
  <!--      var afterpayTab = document.getElementById('tabs-iconpricing-tab-2');-->
  <!--      afterpayTab.classList.remove('active');-->
  <!--      // Add the "active" class to the prepaid tab-->
  <!--      var prepaidTab = document.getElementById('tabs-iconpricing-tab-1');-->
  <!--      prepaidTab.classList.add('active');-->
  <!--    });-->
  <!--  </script>-->
</template>

<script>
import { useStore } from 'vuex';
export default {
  name: 'Pricing',
  beforeMount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', false);
  },
  beforeUnmount() {
    const store = useStore();
    store.commit('toggleEveryDisplay', true);
  },
  methods: {
    getQueryString() {
      return window.location.search;
    },
  }
};
</script>

<style scoped></style>
