<template>
	<head>
	  <meta charset="utf-8">
	  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
	  <title>SK(주) C&C 대량문자발송 솔루션: 개인정보처리방침</title>
    <link rel="icon" href="https://to-go.io/sk_logo.jpg" type="image/jpg">
	  <!--    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>-->
	  <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-icons.css">
	  <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/nucleo-svg.css">
	  <link rel="stylesheet" href="https://demos.creative-tim.com/soft-ui-dashboard/assets/css/soft-ui-dashboard.min.css?v=1.0.2">
	  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
	  <link rel="stylesheet" href="../assets/css/theme.css">
	  <link rel="stylesheet" href="../assets/css/loopple/loopple.css">

	</head>
  
	<body class="g-sidenav-show">	
	<div class="container position-sticky z-index-sticky top-0">
	  <div class="row">
		<div class="col-12">
		  <nav class="navbar navbar-expand-lg  blur blur-rounded top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
			<div class="container-fluid">
                        <!-- <a class="navbar-brand font-weight-bolder ms-sm-3" href="javascript:;" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">Message To-Go</a> -->
                        <a class="navbar-brand ms-sm-3" href="/home#top">                          
                          <!-- <img src="../assets/img/m2g-logo-only.png" alt="Logo" height="30"> -->
                          <img src="../assets/img/SK_logo.svg" alt="Your SVG Image" style="position: relative; width: 30%; height: 20%; margin-top: -10px; margin-bottom: -10px; margin-right: 10px; margin-left: -10px;">
                          <!-- <span class="font-weight-bolder"> Message To-Go</span>                           -->
                        </a>
			  <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
							  <span class="navbar-toggler-icon mt-2">
								  <span class="navbar-toggler-bar bar1"></span>
								  <span class="navbar-toggler-bar bar2"></span>
								  <span class="navbar-toggler-bar bar3"></span>
							  </span>
			  </button>
			  <div id="navigation" class="collapse navbar-collapse pt-3 pb-2 py-lg-0">
				<ul class="navbar-nav navbar-nav-hover ms-lg-12 ps-lg-5 w-100">
				  <li class="nav-item">
					<router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
					  <span class="font-weight-bolder" style="font-size: larger;">채널소개</span>
					</router-link>
				  </li>
				  <li class="nav-item">
					<router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
					  <span class="font-weight-bolder" style="font-size: larger;">기능</span>
					</router-link>
				  </li>
				  <li class="nav-item">
					<router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/product" aria-expanded="false">
					  <span class="font-weight-bolder" style="font-size: larger;">요금제</span>
					</router-link>
				  </li>
				  <li class="nav-item">
					<router-link class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center hover-underline" to="/home/contactus" aria-expanded="false">
					  <span class="font-weight-bolder" style="font-size: larger;">문의하기</span>
					</router-link>
				  </li>
				  <li class="nav-item ms-lg-auto my-auto ms-3 ms-lg-0">
					<router-link class="btn btn-sm  bg-gradient-primary  btn-round mb-0 me-1 mt-2 mt-md-0" :to="`/authentication/signin/basic`" style="font-size: medium;">
					  회원가입/로그인
					</router-link>
				  </li>
				</ul>
			  </div>
			</div>
		  </nav>
		</div>
	  </div>
	</div>
	<section class="py-3" style="font-size: 0.8rem; margin-left: 30px;">
	  <br><br><br>
		<p style='margin-top:60.0pt;margin-right:0cm;margin-bottom:37.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";text-indent:22.5pt;background:white;'><strong><span style="font-size:30px;color:#111111;">개인정보처리방침</span></strong></p>


<table width="795" style="border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
	<p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p>
    <tbody>
        <tr>
            <td width="83" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">변경일자</p>
            </td>
            <td width="83" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">담당자</p>
            </td>
            <td width="360" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">변경사항</p>
            </td>
            <td width="100" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">이전 변경이력 조회</p>
            </td>
        </tr>
        <tr>
            <td width="83" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">2023.09.05</p>
            </td>
            <td width="83" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">박승현</p>
            </td>
            <td width="360" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">최초</p>
            </td>
            <td width="100" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;"></p>
            </td>
        </tr>
        <tr>
            <td width="83" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">2024.04.16</p>
            </td>
            <td width="83" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">박승현</p>
            </td>
            <td width="360" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">제4조 (수집한 개인정보의 위탁)<br /> 수탁자 추가 : <br>SK텔링크, 엔에이치엔클라우드(주), 세종텔레콤 주식회사<br /> <br /> 제11조 (개인정보보호책임자 및 담당자의 연락처)<br /> 개인정보보호책임자 변경 :<br>김완종-&gt;박준</p>
            </td>
            <td width="100" style="border: 1px solid #111111; padding: 8px;">
              <a href="/home/privacy_20240416.html"
                class="nav-link pe-0 text-muted font-weight-bold"
                target="_blank"
                style="font-size: 1rem; text-decoration: underline; color: #007BFF; cursor: pointer;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: inherit;">이력보기</p>
              </a>
            </td>
        </tr>
        <tr>
            <td width="83" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">2024.04.29</p>
            </td>
            <td width="83" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">박승현</p>
            </td>
            <td width="360" style="border: 1px solid #111111; padding: 8px;">
                <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">제1조 (수집하는 개인정보의 항목 및 수집방법<br> 수집하는 개인정보의 항목 추가 : <br> 조직명 <br> <br> 제4조 (수집한 개인정보의 위탁)<br /> 수탁자 추가 : <br>(주)에스엠티엔티</p>
            </td>
            <td width="100" style="border: 1px solid #111111; padding: 8px;">
              <a href="/home/privacy_20240429.html"
                class="nav-link pe-0 text-muted font-weight-bold"
                target="_blank"
                style="font-size: 1rem; text-decoration: underline; color: #007BFF; cursor: pointer;">
                <p style="margin: 0; text-align: center; font-size: 13px; color: inherit;">이력보기</p>
              </a>
            </td>
        </tr>
    </tbody>
</table>


<br>
<p style='margin-top: 0cm; margin-right: 0cm; margin-bottom: 7.5pt; margin-left: 0cm; text-align: left; font-size: 22px; font-family: "맑은 고딕"; line-height: 1.5; background: white; color: #111111;'>
    <strong>총 칙</strong>
</p>
<p style='margin: 0; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 1.6; background: white; color: #111111;'>
    SK㈜(이하 “회사”)은 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 모든 관련 법규를 준수하며, 회사의 서비스를 이용하는 고객(이하 “이용자”)의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다.<br>
    회사는 개인정보처리방침의 공개를 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지를 알려드립니다.<br>
    본 개인정보처리방침은 관련 법령의 개정이나 회사 내부방침에 의해 변경될 수 있으므로 회원 가입시나 사이트 이용시에 수시로 확인하여 주시기 바랍니다.
</p>
<p style='margin-top: 7.5pt; margin-right: 0cm; margin-bottom: 3.75pt; margin-left: 36.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; background: white;'>
    <strong>
        <span style="font-size: 14px; color: #111111;">제1조 수집하는 개인정보의 항목 및 수집방법</span><br>
        <span style="font-size: 14px; color: #111111;">제2조 개인정보의 수집 및 이용목적</span><br>
        <span style="font-size: 14px; color: #111111;">제3조 개인정보의 공유 및 제공</span><br>
        <span style="font-size: 14px; color: #111111;">제4조 개인정보 처리의 위탁</span><br>
        <span style="font-size: 14px; color: #111111;">제5조 개인정보의 보유 및 이용기간</span><br>
        <span style="font-size: 14px; color: #111111;">제6조 개인정보 파기절차 및 방법</span><br>
        <span style="font-size: 14px; color: #111111;">제7조 이용자 및 법정대리인의 권리와 그 행사 방법</span><br>
        <span style="font-size: 14px; color: #111111;">제8조 개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</span><br>
        <span style="font-size: 14px; color: #111111;">제9조 개인정보의 안전성 확보 조치</span><br>
        <span style="font-size: 14px; color: #111111;">제10조 영업 양수 등의 통지</span><br>
        <span style="font-size: 14px; color: #111111;">제11조 개인정보보호책임자 및 담당자의 연락처</span><br>
        <span style="font-size: 14px; color: #111111;">제12조 정보주체의 권익 침해에 대한 구제방법</span><br>
        <span style="font-size: 14px; color: #111111;">제13조 링크사이트</span><br>
        <span style="font-size: 14px; color: #111111;">제14조 기타</span>
    </strong>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제1조 (수집하는 개인정보의 항목 및 수집방법)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
    회사는 회원가입, 고객상담, 각종 서비스의 제공을 위하여 최초 회원가입 시 다음과 같은 개인정보를 수집 이용하고 있습니다.<br><br>
    ① 수집하는 개인정보 항목<br>
    <span style="display: block; margin-left: 20pt;">필수정보 - 메시지 투고 서비스를 이용하기 위한 최소한의 정보</span>
    <span style="display: block; margin-left: 20pt;">선택정보 - 이용자에게 서비스의 추가 기능을 제공하기 위해 추가 수집하는 정보</span>
    <span style="display: block; margin-left: 20pt;">(선택정보를 입력 하지 않는 경우에도 서비스 이용 제한은 업습니다)</span>
</p>
<p style='margin-left: 51.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  가) 회원 가입 및 슬레이브 계정 등록 시 수집하는 개인정보 유형 및 항목
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">구분</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">유형</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">수집하는 개인정보 항목</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;" rowspan="2">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">회원가입</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 아이디, 비밀번호, 업체명<br>- 서비스 담당자 정보(성명, 휴대전화번호, 이메일, 조직명)</p>
      </td>
    </tr>
    <tr>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">선택</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">[사용자와 정산 담당자가 상이한 경우]<br>- 정산담당자정보(성명, 휴대전화번호, 유선전화번호, 이메일)<br>- 대부업등록증 기재 정보(대부업등록증 사본)<br></p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">메세징 서비스 이용 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">[발신번호 등록 시]<br>- 사업자등록증 기재 정보(사업자등록번호, 업태, 업종, 회사주소, 대표자명 등)<br>[Agent 서비스 이용 시]<br>- 모듈 연동 환경 정보 (OS정보, DBMS정보, 접속허용IP, 이용서비스 등)</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">서비스 이용 문의 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 이름, 회사명, 조직명, 전화번호, 이메일</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;" rowspan="2">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">슬레이브 계정 등록</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 아이디, 비밀번호<br>- 서비스 담당자정보(성명, 휴대전화번호, 유선전화번호, 이메일)</p>
      </td>
    </tr>
    <tr>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">선택</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 정산담당자정보(성명, 휴대전화번호, 유선전화번호, 이메일)<br>- 대부업등록증 기재 정보(대부업등록증 사본)</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='margin-left: 51.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  나) 서비스 이용과정이나 사업처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집되는 정보
  <span style="display: block; margin-left: 20pt;">- 접속정보(IP Address, 쿠키, 방문 일시, 서비스 이용 기록), 불량 이용 기록</span>
</p>
<p style='margin-left: 51.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  다) 유료 서비스 이용 시 다음과 같은 정보들이 수집되는 정보
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">구분</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">유형</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">수집하는 개인정보 항목</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">계좌이체 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 은행명, 계좌번호 등</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">전자세금계산서 발행 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 사업자등록번호, 대표자명, 사업장주소, 업태, 품목 등</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">자동이체 신청 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 출금계좌 정보(은행명, 계좌번호, 예금주명, 사업자등록번호, 생년월일(개인계좌인 경우), 전화번호), 통장사본</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='margin-left: 51.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  라) 부가적으로 이용자 동의 후 수집하는 개인정보 유형 및 항목
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">구분</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">유형</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">수집하는 개인정보 항목</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">온라인 고객 상담 신청 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 성명, 전화번호, 이메일</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">웹 발송 인증 기능 이용 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 성명, 휴대전화번호</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">발송 감시 기능 이용 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 휴대전화번호</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">광고 유입을 통한 신규 견적 문의 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 업체명, 업종, 성명, 휴대전화번호, 조직명</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">회사 내부 서비스 업무처리 시</p>
      </td>
      <td width="10" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">필수</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 휴대전화번호</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  ② 개인정보 수집방법<br>
    <span style="display: block; margin-left: 20pt;">회사는 다음과 같은 방법으로 개인정보를 수집합니다.</span>
    <span style="display: block; margin-left: 20pt;">가) 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모</span>
    <span style="display: block; margin-left: 20pt;">나) 제휴회사로부터의 제공</span><br>
  ③ 회사는 기본적 인권침해의 우려가 있는 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지 않습니다.
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제2조 (개인정보의 수집 및 이용목적)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 수집한 개인정보를 다음의 목적으로 활용합니다.<br><br>
    ① 서비스 제공에 관한 계약 이행 및 유료 서비스 제공에 따른 요금정산<br>
    <span style="display: block; margin-left: 20pt;">콘텐츠 제공, 유료 서비스 이용에 대한 과금, 구매 및 요금 결제, 본인인증, 청구서 등 발송, 요금추심</span>
    ② 회원관리<br>
    <span style="display: block; margin-left: 20pt;">회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 중복가입 확인, 가입의사 확인, 분쟁 조정을 위한 기록보전, 불만처리 등 민원처리, 고지사항 전달</span>
    ③ 신규서비스 개발 및 마케팅 광고에 활용<br>
    <span style="display: block; margin-left: 20pt;">신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계</span>
    ④ 회사 내부 시스템 업무 처리에 활용<br>
    <span style="display: block; margin-left: 20pt;">회사 내부의 전사 개인정보 처리 동의한 구성원의 개인정보를 회사업무 수행의 목적으로 이용</span>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제3조 (개인정보의 공유 및 제공)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 이용자의 개인정보를 제2조(개인정보의 수집 및 이용목적)에서 고지한 범위 내에서 이용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 타인 또는 타 기관에 제공 또는 공유하지 않습니다. <br>다만, 다음의 경우에는 예외로 합니다.<br><br>
    ① 이용자가 사전에 동의한 경우<br>
    ② 법령의 규정에 의거하거나, 수사상 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br>
    ③ 서비스 제공에 따른 요금 정산을 위해 필요한 경우<br>
    ④ 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제4조 (개인정보 처리의 위탁)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 서비스 이행을 위해 이용자의 개인정보를 위탁 처리할 경우 위탁하는 내용 및 수탁자를 고지하고, 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정해 관련 법규를 준수하고 이를 감독합니다. <br>회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 다음과 같습니다.<br>
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="500" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">수탁업체명</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">위탁업무 내용</p>
      </td>
    </tr>
    <tr>
      <td width="500" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">SK텔링크, ㈜다우기술, 엔에이치엔클라우드(주), 세종텔레콤 주식회사, (주)에스엠티엔티</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 메시지 발송 서비스</p>
      </td>
    </tr>
    <tr>
      <td width="500" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">NICE평가정보㈜</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 휴대폰 본인인증</p>
      </td>
    </tr>
    <tr>
      <td width="500" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">(주)에이쓰리시큐리티, SK쉴더스</p>
      </td>
      <td width="100" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 보안관제 서비스,DB접근제어 서비스</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">AWS</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: left; font-size: 13px; color: #111111;">- 개인정보처리를 위한 시스템활용</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제5조 (개인정보의 보유 및 이용기간)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  ① 이용자의 개인정보는 이용자가 회원탈퇴를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 개인정보의 수집 및 이용목적이 달성되는 경우에는 지체 없이(단, 이용자가 회원탈퇴를 요청하거나<br> 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우에는 이용자의 실수 혹은 변심에 의한 회원탈퇴 등의 철회 시 이용자의 정보 복구를 위하여 회원탈퇴 후 7일이 경과한 시점에) 파기합니다. <br>다만, 다음의 정보에 대해서는 보존근거에서 명시한 근거에 따라 보존기간 동안 보존합니다.<br>
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존항목</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존근거</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존기간</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">상호, 사업자등록번호, 서비스 이용기록, 접속 로그, 해지일</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px;" rowspan = "2">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">회원 탈퇴 시 이용자의 불만 및 분쟁해결 등을 위한 목적<br>ID 중복 사용 방지<br>부정 가입 및 이용 방지<br>불법적 이용자에 대한 관련 기관 수사협조</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">1년</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">아이디, 결제기록</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">5년</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  ② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의해 보존할 필요성이 있는 경우, 회사는 관계법령에서 정한 일정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이 경우 회사는 보관하는 <br>정보를 그 보관의 목적으로만 이용하며, 보존근거에서 명시한 근거에 따라 보존기간 동안 보존합니다. 관계법령에 따른 보관 근거, 보관하는 항목, 보관 기간의 예를 들면 다음과 같습니다.<br>
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존항목</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존근거</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존기간</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">계약 또는 청약철회 등에 관한 기록</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px;" rowspan = "5">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">전자상거래 등에서의 소비자보호에 관한 법률</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">5년</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">대금결제 및 재화 등의 공급에 관한 기록</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">5년</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">소비자의 불만 또는 분쟁처리에 관한 기록</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">3년</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">표시/광고에 관한 기록</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">6개월</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">대금결제 및 재화 등의 공급에 관한 기록</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">5년</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">통신사실확인자료(웹사이트 방문 기록)</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">통신비밀보호법</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">3개월</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">통신사실확인자료(이용자의 전기통신일시 및 전기통신 개시·종료시간, 상대방 가입자 번호, 사용도수)</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">통신비밀보호법</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">12개월</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  ③ 회사 내부 시스템의 회사업무 수행을 위해 수집된 구성원의 개인정보는 법규 준수 등을 위해 아래 기간 동안 보관 후 파기합니다.<br>
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존항목</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존근거</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">보존기간</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">휴대전화번호</p>
      </td>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">관련 법규 준수,재직 중 인사관리,복리후생,퇴직 후 재직증명서 및 경력증명서 발급, 회사 업무 등</p>
      </td>
      <td width="50" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">5년<br>(본인의 별도의 의사표시가 없는 한 퇴사 후, 단 법령상 의무를 준수하기 위하여 필요한 경우 그에 의함)</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제6조 (개인정보 파기절차 및 방법)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.<br><br>
    ① 파기절차<br>
    <span style="display: block; margin-left: 20pt;">가. 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부방침 및 기타 관계법령에 의한 정보보호 사유에 따라 일정 기간 저장 후 파기됩니다.</span>
    <span style="display: block; margin-left: 20pt;">나. 상기 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</span>
    ② 파기방법<br>
    <span style="display: block; margin-left: 20pt;">가. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</span>
    <span style="display: block; margin-left: 20pt;">나. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</span>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제7조 (이용자 및 법정대리인의 권리와 그 행사 방법)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  ① 이용자 및 법정대리인은 언제든지 이용자 본인의 개인정보를 조회하거나 정정할 수 있으며, 가입해지(회원탈퇴) 또는 개인정보의 삭제를 요청할 수 있습니다. 단, 서비스 제공을 위해 반드시 필요한 개인정보를 삭제하는 경우 관련 서비스를 제공받지 못할 수 있습니다.<br>
  ② 이용자의 개인정보를 조회, 정정하기 위하여는 개인정보변경을 개인정보보호책임자 및 담당자에게 서면, 전화 또는 이메일로 연락하시면 본인 확인 절차 후 지체 없이 필요한 조치를 하겠습니다.<br>
  ③ 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br>
  ④ 회사는 이용자 또는 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보를 제5조(개인정보의 보유 및 이용기간)에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제8조 (개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  ① 회사는 이용자에게 개인화되고 맞춤화된 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 찾아내는 쿠기(Cookie)를 사용합니다. 쿠키는 웹사이트가 이용자의 웹브라우저(인터넷익스플로러, 파이어폭스, 크롬 등)로 전송하는 소량의 정보입니다.<br>
  ② 이용자는 쿠키에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 [도구] > [인터넷옵션] > [개인정보] > [설정]을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. <br>단, 모든 쿠키의 저장을 거부하는 경우, 쿠키를 통해 회사에서 제공하는 서비스를 이용할 수 없습니다.<br>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제9조 (개인정보의 안전성 확보 조치)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위해 기술적, 관리적 대책을 마련하고 있습니다.<br><br>
  1. 기술적 대책<br>
  <span style="display: block; margin-left: 20pt;">가. 이용자의 비밀번호는 암호화되어 있어 이용자 본인 외에는 알 수 없습니다.</span>
  <span style="display: block; margin-left: 20pt;">나. 회사는 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">다. 회사는 해킹이나 컴퓨터바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">라. 회사는 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">마. 회사는 시스템에 대한 접근통제, 권한 관리, 취약점 점검 등의 조치를 통해 보안성이 강화될 수 있도록 지속적으로 노력하고 있습니다.</span>
  2. 관리적 대책<br>
  <span style="display: block; margin-left: 20pt;">가. 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">나. 회사는 개인정보처리자를 대상으로 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">다. 회사는 개인정보보호 업무를 전담하는 부서에서 개인정보처리방침 및 내부 규정 준수여부를 확인하여 문제가 발견될 경우, 즉시 바로 잡을 수 있도록 노력하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">라. 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 회사는 책임을 지지 않습니다.</span>
  3. 물리적 대책<br>
  <span style="display: block; margin-left: 20pt;">가. 전산실, 자료보관실 등 개인정보를 부관하고 있는 물리적 보관장소에 대한 출입통제 절차를 수립, 운영하고 있습니다.</span>
  <span style="display: block; margin-left: 20pt;">나. 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</span>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제10조 (영업 양수 등의 통지)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 영업의 전부 또는 일부를 양도하거나 합병, 상속 등으로 그 권리, 의무를 이전하는 경우 이용자에게 관련 내용으로 다음의 항목을 통지합니다.<br><br>
  1. 영업의 전부 또는 일부의 양도, 합병 또는 상속의 사실<br>
  2. 권리, 의무를 승계한 자의 성명, 주소, 연락처<br>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제11조 (개인정보보호책임자 및 담당자의 연락처)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 다음과 같이 개인정보보호책임자 및 담당자를 두고 있습니다.<br>
</p>
<table width="1000" style="margin-left:51.0pt; border-collapse: collapse; font-family: '맑은 고딕', sans-serif;">
		<!-- <p style='margin-top:0cm;margin-right:0cm;margin-bottom:7.5pt;margin-left:0cm;text-align:left;font-size:13px;font-family:"맑은 고딕";line-height:16.8pt;background:white;'><strong><span style="font-size:22px;color:#111111;">개정 이력</span></strong></p> -->
  <tbody>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">구분</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">개인정보보호책임자</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px; background-color: #f2f2f2;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111; font-weight: bold;">개인정보보호담당자</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">성명</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">박준</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">박승현</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">소속 및 직위</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">DX부문/부문장</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">M2G TF/매니저</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">전화번호</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">02-6400-2613</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">02-6400-2613</p>
      </td>
    </tr>
    <tr>
      <td width="200" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">이메일</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">message.to-go@sk.com</p>
      </td>
      <td width="360" style="border: 1px solid #111111; padding: 8px;">
        <p style="margin: 0; text-align: center; font-size: 13px; color: #111111;">message.to-go@sk.com</p>
      </td>
    </tr>
  </tbody>
</table><br>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제12조 (정보주체의 권익 침해에 대한 구제방법)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  이용자(정보주체)는 개인정보침해로 인한 구제를 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.<br><br>
  <span style="display: block; margin-left: 20pt;">가. 개인정보 침해신고센터(privacy.kisa.or.kr/ 국번 없이118)</span>
  <span style="display: block; margin-left: 20pt;">나. 대검찰청 사이버수사과(spo.go.kr/ 국번 없이 1301)</span>
  <span style="display: block; margin-left: 20pt;">다. 경찰청 사이버안전국(cyberbureau.police.go.kr/ 국번 없이182)</span>
  <span style="display: block; margin-left: 20pt;">라. 개인정보 분쟁조정위원회(kopico.go.kr/ 1833-6972)</span>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제13조 (링크사이트)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  회사는 이용자에게 다른 회사의 웹사이트에 대한 링크를 제공할 수 있습니다. 이 경우 링크되어 있는 웹사이트가 개인정보를 수집하는 행위에 대해서는 본 개인정보처리방침이 적용되지 않습니다.<br><br>
</p>
<p style='text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: 16.8pt; background: white;'>
    <span style="font-size: 18px; color: #4394FE;">제14조 (기타)</span>
</p>
<p style='margin-left: 31.0pt; text-align: left; font-size: 13px; font-family: "맑은 고딕"; line-height: normal; background: white; color: #111111;'>
  본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 최소 7일 전부터 홈페이지를 통해 고지할 것입니다.<br><br>
</p>
</section>
  
  
  
	<section class="py-5 position-relative">
	  <div class="page-header min-vh-50 m-3 border-radius-xl" style="background-image: url('https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/curved-images/curved8.jpg');">
		<span class="mask bg-gradient-dark opacity-4"></span>
		<div class="container">
		  <div class="row justify-content-center">
			<div class="col-lg-6 text-center mx-auto">
			  <h1 class="text-white mt-4 mb-1">질문이 있으신가요?</h1>
			  <p class="lead text-white mb-6">저희 솔루션에 대해 더욱 자세히 설명드리겠습니다.</p>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="container">
		<div class="row mt-n7 blur border-radius-lg shadow-blur">
		  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
			<div class="p-3 text-center">
			  <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
				<svg class="mt-3" width="15px" height="15px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				  <title>map-big</title>
				  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g transform="translate(-2321.000000, -593.000000)" fill="#FFFFFF" fill-rule="nonzero">
					  <g transform="translate(1716.000000, 291.000000)">
						<g id="map-big" transform="translate(605.000000, 302.000000)">
						  <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
						  <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
						  <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
						  <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
						</g>
					  </g>
					</g>
				  </g>
				</svg>
			  </div>
			  <h5 class="mt-3 mb-1">주소</h5>
			  <p class="mb-0">경기도 성남시 분당구 <br> 성남대로343번길 9 (SK u타워)</p>
			  <hr class="vertical dark">
			</div>
		  </div>
		  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
			<div class="p-3 text-center">
			  <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
				<svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				  <title>document</title>
				  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
					  <g transform="translate(1716.000000, 291.000000)">
						<g transform="translate(154.000000, 300.000000)">
						  <path d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z" opacity="0.603585379"></path>
						  <path d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"></path>
						</g>
					  </g>
					</g>
				  </g>
				</svg>
			  </div>
			  <h5 class="mt-3 mb-1">이메일</h5>
			  <p class="mb-0"><br>message.to-go@sk.com</p>
			</div>
			<hr class="vertical dark">
		  </div>
		  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
			<div class="p-3 text-center">
			  <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
				<svg class="mt-3" width="15px" height="15px" viewBox="0 0 44 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				  <title>megaphone</title>
				  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g transform="translate(-2168.000000, -591.000000)" fill="#FFFFFF" fill-rule="nonzero">
					  <g transform="translate(1716.000000, 291.000000)">
						<g id="megaphone" transform="translate(452.000000, 300.000000)">
						  <path d="M35.7958333,0.273166667 C35.2558424,-0.0603712374 34.5817509,-0.0908856664 34.0138333,0.1925 L19.734,7.33333333 L9.16666667,7.33333333 C4.10405646,7.33333333 0,11.4373898 0,16.5 C0,21.5626102 4.10405646,25.6666667 9.16666667,25.6666667 L19.734,25.6666667 L34.0138333,32.8166667 C34.5837412,33.1014624 35.2606401,33.0699651 35.8016385,32.7334768 C36.3426368,32.3969885 36.6701539,31.8037627 36.6666942,31.1666667 L36.6666942,1.83333333 C36.6666942,1.19744715 36.3370375,0.607006911 35.7958333,0.273166667 Z"></path>
						  <path d="M38.5,11 L38.5,22 C41.5375661,22 44,19.5375661 44,16.5 C44,13.4624339 41.5375661,11 38.5,11 Z" opacity="0.601050967"></path>
						  <path d="M18.5936667,29.3333333 L10.6571667,29.3333333 L14.9361667,39.864 C15.7423448,41.6604248 17.8234451,42.4993948 19.6501416,41.764381 C21.4768381,41.0293672 22.3968823,38.982817 21.7341667,37.1286667 L18.5936667,29.3333333 Z" opacity="0.601050967"></path>
						</g>
					  </g>
					</g>
				  </g>
				</svg>
			  </div>
			  <h5 class="mt-3 mb-1">전화번호</h5>
			  <p class="mb-0"><br>(02)6400-2613</p>
			</div>
			<hr class="vertical dark">
		  </div>
		  <div class="col-lg-3 col-md-6 col-6 position-relative my-auto">
			<div class="p-3 text-center">
			  <div class="icon icon-shape bg-gradient-primary shadow text-center rounded-circle">
				<svg class="mt-3" width="15px" height="15px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				  <title>ungroup</title>
				  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g transform="translate(-2170.000000, -442.000000)" fill="#FFFFFF" fill-rule="nonzero">
					  <g transform="translate(1716.000000, 291.000000)">
						<g id="ungroup" transform="translate(454.000000, 151.000000)">
						  <path d="M38.1818182,10.9090909 L32.7272727,10.9090909 L32.7272727,30.9090909 C32.7272727,31.9127273 31.9127273,32.7272727 30.9090909,32.7272727 L10.9090909,32.7272727 L10.9090909,38.1818182 C10.9090909,39.1854545 11.7236364,40 12.7272727,40 L38.1818182,40 C39.1854545,40 40,39.1854545 40,38.1818182 L40,12.7272727 C40,11.7236364 39.1854545,10.9090909 38.1818182,10.9090909 Z" opacity="0.6"></path>
						  <path d="M27.2727273,29.0909091 L1.81818182,29.0909091 C0.812727273,29.0909091 0,28.2781818 0,27.2727273 L0,1.81818182 C0,0.814545455 0.812727273,0 1.81818182,0 L27.2727273,0 C28.2781818,0 29.0909091,0.814545455 29.0909091,1.81818182 L29.0909091,27.2727273 C29.0909091,28.2781818 28.2781818,29.0909091 27.2727273,29.0909091 Z"></path>
						</g>
					  </g>
					</g>
				  </g>
				</svg>
			  </div>
			  <h5 class="mt-3 mb-1">영업 담당자</h5>
			  <p class="mb-0"><br>박승현 매니저</p>
			</div>
		  </div>
		</div>
		<div class="container">
		  <br>
		  <div class="container-fluid">
			<div class="row align-items-center justify-content-lg-between">
			  <div class="mb-4 col-lg-9 mb-lg-0">
				<div
				  class="text-sm text-center copyright text-muted"
				  :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
				>
				©
          {{ new Date().getFullYear() }}, made
          <!-- <i class="fa fa-heart"></i>  -->
          by
          <a
            href="https://www.sk.com"
            class="font-weight-bold"
            target="_blank"
            >SK 주식회사 C&C</a
          >
				</div>
				<span style="font-size: 0.8rem; margin-right: 0.5rem;">
			SK(주) | 대표이사: 장용호, 최태원 | 경기도 성남시 분당구 성남대로 343번길 9 | 사업자등록번호 783-85-00169 | 통신판매업신고 2015-경기성남-1388 <br> COPYRIGHT SK HOLDINGS CO., LTD. ALL RIGHTS RESERVED.
			</span>
			  </div>
			  <div class="col-lg-2">
				<ul
                      class="nav nav-footer"
                    >
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 이용 약관_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >이용약관</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/서비스 수준 약정서(SLA)_SK(주)_Message To-Go.pdf"
                          class="nav-link text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 0.8rem;"
                          >서비스수준(SLA)</a
                        >
                      </li>
                      <li class="nav-item" style="margin-bottom: -10px;">
                        <a
                          href="/home/privacy"
                          class="nav-link pe-0 text-muted font-weight-bold"
                          target="_blank"
                          style="font-size: 1rem;"
                          >개인정보처리방침</a
                        >
                      </li>
                    </ul>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	</section>
	<!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>-->
	<!--  <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/soft-ui-dashboard.min.js?v=1.0.2"></script>-->
	<!-- <script src="../assets/js/loopple/loopple.js"></script> -->
	</body>
	<!--  <script>-->
	<!--    document.addEventListener('DOMContentLoaded', function() {-->
	<!--      // Remove the "active" class from the afterpay tab-->
	<!--      var afterpayTab = document.getElementById('tabs-iconpricing-tab-2');-->
	<!--      afterpayTab.classList.remove('active');-->
	<!--      // Add the "active" class to the prepaid tab-->
	<!--      var prepaidTab = document.getElementById('tabs-iconpricing-tab-1');-->
	<!--      prepaidTab.classList.add('active');-->
	<!--    });-->
	<!--  </script>-->
  </template>
  
  <script>
  import { useStore } from 'vuex';
  export default {
	  name: 'Pricing',
	  beforeMount() {
	    const store = useStore();
	    store.commit('toggleEveryDisplay', false);
	  },
	  beforeUnmount() {
	    const store = useStore();
	    store.commit('toggleEveryDisplay', true);
	  },
    methods: {
      getQueryString() {
        return window.location.search;
      },
    }
  };
  </script>
  
  <style scoped></style>
